<template>

    <PopupVideo v-if="showPopup" :popupCont="popupCont" @popup-out="handleData"></PopupVideo>

	<div class="my-scrollbar" style="height: 100vh">

		<section class="st first-cont" id="first-cont" ref="firstCont">

			<div class="st st-content gen_margin contlay-hoz bg-fullwidth gen_bg bg-py-t bg-px-c bg-rp-no bg-fx-st bg-sz-cv sec-mainbanner"
				genbg="genBg" :data-bg-d="`${require('@/assets/image/home/bg-home-banner-d.jpg')}`"
				:data-bg-m="`${require('@/assets/image/home/bg-home-banner-m.jpg')}`" data-column="2">
				<canvas id="canvas_galaxy"></canvas>
				<div class="bg-blend-img"></div>
				<div class="container-fluid text-middle endH position-relative"
					data-image-desktop="/uploads/bg-home-about-d-test.jpg" data-image-mobile="" data-column="1">
					<div class="sec-contain fw gen_padding  pd" data-pd-desktop="" data-pd-mobile="">
						<div class="cont-contain pd cont-abs align-items-md-center text-left text-start  ">
							
                            <div class="cont-img position-relative order-first order-sm-2">
								<div id="orbit-system">
									<div class="system">
                                        <div class="system-radious"></div>
                                        <div class="system-radious system-radious-2"></div>
										<div class="satellite-orbit">
											<div class="satellite"><img
													src="@/assets/image/home/ball-silver.png" alt="">
											</div>
											<div class="satellite"><img
													src="@/assets/image/home/ball-silver.png" alt="">
											</div>
										</div>
										<div class="planet"><img
												src="../assets/image/home/trianble-hologram-global-banner.png" alt=""></div>
									</div>
								</div>
							</div>
							<div class="cont-txt order-2 order-sm-first">
								<div class="row">
									<div class="col col-12 col-md-12 col-xl-12 offset-md-0">
										<div class="box-oversize ">
											<!-- <h1 class="text-light text-upper text-bold spilttext position-absolute">
												PUSH FORWARD$
												DIGITAL BOUNDARIES$
												TO REACH NEW
											</h1>
											<h1 class="text-upper text-bold color-tranparent ">
												PUSH FORWARD<br>
												DIGITAL BOUNDARIES<br>
												TO REACH NEW
											</h1> -->
                                            <h1 class="text-light text-upper text-bold  ">
                                                <span class="headline-group headline-group-1">
                                                    <span class="position-relative color-tranparent">PUSH FORWARD</span>
                                                    <span class="position-absolute ">PUSH FORWARD</span>
                                                </span>
                                                <span class="headline-group headline-group-2">
                                                    <span class="position-relative color-tranparent">DIGITAL BOUNDARIES</span>
                                                    <span class="position-absolute ">DIGITAL BOUNDARIES</span>
                                                </span>
                                                <span class="headline-group headline-group-3">
                                                    <span class="position-relative color-tranparent">TO REACH NEW</span>
                                                    <span class="position-absolute ">TO REACH NEW</span>
                                                </span>
                                            </h1>
											<!-- <h1 class="randomtext text-ctr-primary mt-2 mb-2 didot">
                                                <span class="text-scroll-loop">
                                                    <span>POSSIBILITIES</span>
                                                    <span>CREATIVITIES</span>
                                                    <span>TECHNOLOGIES</span>
                                                </span>
                                            </h1> -->
                                            <h1 class="randomtext text-ctr-primary didot">
                                                <div class="rand-blank">POSSIBILITIES</div>
                                                <!-- <span class="rand-txt rand-txt-0">POSSIBILITIES</span>
                                                <span class="rand-txt rand-txt-1">CREATIVITY</span>
                                                <span class="rand-txt rand-txt-2">TECHNOLOGIES</span> -->
                                            </h1>

                                            
											<!-- <h1 class="spilttext-bilnd"></h1> -->
											<div class="fw mt-4">
                                                <a href="/works" class="btn btn-icon btn-white btn-text text-upper"><span><span>Our works</span><img class="svg" src="@/assets/image/skin/icon-arrow.svg" alt=""></span></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>

			<!-- <VideoLoopLayer /> -->

		</section>
		<section class="st scrollable-inner">
			<WorkList @videoIDSent="getValue"/>
		</section>

		<section class="st" id="next-cont">
			<ContentService />

			<ClientList />
			<LetUsHelpView />
		</section>
		<FooterView />
	</div>

	<div id="box-green" :data-scroll-sticky="isSticky" :style="{ top: translateYBox + 'px' }">
		<div class="st text-loop">
			<div class="fw overflow-hidden">
				<div class="container-fluid " ref="container">
					<div class="scrolling-text" ref="scrollingText">
						<div class="scrolling-text-content text-start position-relative"
							:style="{ left: translateXText + 'px' }">
							{{ scrollingTextContent }}
						</div>
					</div>
					<div class="scrolling-text float-end" ref="scrollingText">
						<div class="scrolling-text-content text-end position-relative"
							:style="{ right: translateXText + 'px' }">
							{{ scrollingTextContent }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { setFunction } from "@/global";
import Scrollbar from "smooth-scrollbar";
import PopupVideo from "../components/content/PopupVideoView.vue";
import VideoLoopLayer from "../components/videos/VideoLoopLayerView"
import WorkList from "../components/lists/WorkListView"
import ClientList from "../components/lists/ClientListView"
import ContentService from "../components/content/ContentServiceView"
import LetUsHelpView from "../components/content/LetUsHelpView"
import FooterView from "../components/FooterView.vue";

import { TweenMax } from 'gsap';

export default {
	name: 'App',
	components: {
		VideoLoopLayer,
        PopupVideo,
		WorkList,
		ClientList,
		ContentService, LetUsHelpView, FooterView,
	},
	data() {
		return {
			scrollStatus: {},
			publicPath: process.env.BASE_URL,
			isSticky: false,
			translateY: 0,
			translateYBox: 0,
			translateXText: 0,
			isEnd: false,
			height: 0,
			imgHeight: 0,
			isH: 0,
			deviceType: '',
			getImage: '',
			genH: '',
			currentY: 0,
			scrollingTextContent: 'Recent works Recent works Recent works Recent works Recent works Recent worksRecent works Recent works Recent worksRecent works Recent works Recent works',
            t : '',
            i : 0,
            spilttext : '',
            showPopup : false,
            videoID : '',
            popupCont :'',

		};
	},
	mounted() {

		if (window.innerWidth < 768) {
			this.deviceType = 'mobile';
		} else {
			this.deviceType = 'desktop';
		}

		this.setBackgroundImage();
		this.genHeight();
		this.lazySection();


		window.addEventListener('resize', () => {
			this.setBackgroundImage();
			this.genHeight();
			this.lazySection();
            this.text_rand_banner()
		});
		window.addEventListener('load', () => {
            setFunction();
			this.lazySection();
			this.galaxy();
			//this.text_render();
            this.home_animate();
            this.text_rand_banner()
		});
        
        // this.t = document.querySelector('.spilttext').textContent;
        // this.i = 0;
        // this.spilttext = document.querySelector('.spilttext');
        // this.spilttext.innerHTML = '';



        // const textScrollLoop = document.querySelector('.text-scroll-loop');
        // const textScrollItems = textScrollLoop.querySelectorAll('span');
        // const textScrollHeight = textScrollItems[0].clientHeight; // get the height of each scroll item
        
        

        

        


	},
	computed: {
		// randomDurations() {
		//       let durations = [];
		//       for (let i = 0; i < 6; i++) {
		//         durations.push(Math.floor(Math.random() * 50) + 5);
		//       }
		//       return durations;
		//     }
	},
	methods: {
        getValue(value) {
            this.videoID = value;

            if(this.videoID != ''){
                this.showPopup = true
                this.popupCont = { videoID_props: this.videoID };
            }
            
        },

        handleData(data){
            this.showPopup = false;
        },

		handleOnLoad(scrollbar) {
			if (this.isSticky == true) {
				this.imgHeight = this.$refs.firstCont.clientHeight;
				this.translateYBox = 0;
				// console.log('1',scrollbar.offset.y , this.imgHeight);
			} else {
				this.imgHeight = this.$refs.firstCont.clientHeight;
				this.translateYBox = this.imgHeight - scrollbar.offset.y;
				//console.log(this.imgHeight, scrollbar.offset.y);
			}
		},

		lazySection() {
			document.querySelector('body').style.overflow = 'hidden';
			document.querySelector('#app').style.overflow = 'hidden';
			this.scrollingTextContent = this.$refs.scrollingText.querySelector('.scrolling-text-content').textContent;
			const scrollbarElements = document.querySelectorAll(".my-scrollbar");
			

			if (scrollbarElements.length >= 1) {
				scrollbarElements.forEach((element) => {
					const scrollbar = Scrollbar.init(element, {
						damping: 0.04,
						thumbMinSize: 20,
						renderByPixels: true,
						alwaysShowTracks: false,
					});
					// track.element.addEventListener("click", (event) => {
					//   // Your event handling code here
					//   console.log(event);
					// });

					const firstCont = document.querySelector("#first-cont");


					setTimeout(() => {
						this.handleOnLoad(scrollbar);
					}, 200);


					var keepInt = 0;
					scrollbar.addListener((status) => {
						var totalHeight = 0;
						var GettotalHeight = 0;
						var intIndex = 0;

						document.querySelectorAll("section").forEach((element, index) => {

							totalHeight = element.clientHeight;

							if (element.classList.contains("scrollable-inner")) {
								intIndex = index;
							}

							if (index == intIndex) {
								GettotalHeight += totalHeight;

							}
						});

						const result = GettotalHeight;

						if (status.offset.y >= firstCont.offsetHeight && !this.isSticky) {
							this.isSticky = true;
						} else if (status.offset.y < firstCont.offsetHeight && this.isSticky) {
							this.isSticky = false;
						}


						if (this.isSticky == false) {
							this.translateYBox = this.imgHeight - scrollbar.offset.y;

						}

						if (firstCont.offsetHeight - window.innerHeight <= scrollbar.offset.y) {

							if (this.currentY > scrollbar.offset.y) {
								this.translateXText = firstCont.offsetHeight - (scrollbar.offset.y + window.innerHeight);

							} else if (this.currentY < scrollbar.offset.y) {
								this.translateXText = firstCont.offsetHeight - (scrollbar.offset.y + window.innerHeight)
							}

							this.currentY = (scrollbar.offset.y + window.innerHeight);

						}

						if (scrollbar.offset.y + window.innerHeight >= result) {
							if (keepInt == 0) {
								keepInt = scrollbar.offset.y;
								this.isSticky = false;
								// console.log('1',keepInt);
							}
							this.translateYBox = keepInt - scrollbar.offset.y;
						}


						if (scrollbar.offset.y + window.innerHeight == keepInt) {
							// console.log('2',keepInt);
							this.translateYBox = -(scrollbar.offset.y - keepInt);

						}
                        var header = document.querySelector('.box-header');
                        if(scrollbar.offset.y >= 50){
                                header.classList.add('head-down');
                        } else {
                            header.classList.remove('head-down');
                        }
                        
					});
				});
			}
		},
		setBackgroundImage() {

			let genBgEle = document.querySelectorAll('.gen-bg');
			genBgEle.forEach((element) => {
				if (window.matchMedia("(min-width: 991px)").matches) {
					this.getImage = element.getAttribute('data-bg-d');
				} else {
					this.getImage = element.getAttribute('data-bg-m');
				}
				element.style.backgroundImage = `url(${this.getImage})`;
			});
		},
		genHeight() {
			let genHeight = document.querySelectorAll('.gen-height');
			genHeight.forEach((element) => {
				this.genH = element.clientHeight;
			});
		},

        home_animate() {
            
            TweenMax.from('.bg-blend-img',5, { opacity: 0}); 
            TweenMax.from('.planet', 4, { opacity: 1, y: -100, x:100, scale:.3 },0); 
            TweenMax.from('.system-radious', 4, { opacity: 0, scale:0.3},2); 
            TweenMax.from('.satellite img', 4, { opacity: 0, scale:0.3},3); 

            TweenMax.from('.headline-group-1 .position-absolute', 1, { opacity: 0, y:200, skewY:'15deg' },1); 
            TweenMax.from('.headline-group-2 .position-absolute', 1, { opacity: 0, y:200, skewY:'15deg'},1.2); 
            TweenMax.from('.headline-group-3 .position-absolute', 1, { opacity: 0, y:200, skewY:'15deg'},1.4); 
            TweenMax.from('.sec-mainbanner .cont-txt .randomtext', 1, { opacity: 0, y:70,},2); 
            TweenMax.from('.sec-mainbanner .cont-txt .btn', 1, { opacity: 0, y:100,},2.2); 
            
        },


        text_render() {
            if (this.t[this.i] == '$') {
                this.spilttext.appendChild(document.createElement('br'));
            } else {
                const span = document.createElement('span');
                span.textContent = this.t[this.i];
                this.spilttext.appendChild(span);
            }
            this.i++;
            if (this.i < this.t.length) {
                setTimeout(this.text_render, 50);
            }else{
                document.querySelectorAll('.randomtext').forEach((element,index) => {
                    setTimeout(() => {
                        element.classList.add('animate__animated', 'animate__fadeIn' , 'show');
                    }, 500 * index);
                });
            }

        },
        text_rand_banner() {
            // const textRand = document.querySelectorAll('.rand-txt');
            // console.log(textRand.length);
            // textRand.forEach((element) => {
            //     //textRand.style.top = textRand.offsetHeight + 'px';
            //     console.log(textRand.offsetHeight);
            //     myDiv.replaceChild(newElement, myDiv.firstChild);
            // });
            
            // var rand_txt = $('.rand-txt');
            // var numTextRandom = rand_txt.length;
            // rand_txt.css({top:'100%'});
            // setInterval(function () {
            //     if(index == 0){
            //         $('.rand-txt-'+(index)).animate({top:'-100%'});
            //     }
            //     $('.rand-txt-'+(index)).animate({top:'-100%'});
            //     if(index == numTextRandom){
            //         index = 0
            //     }else{
            //         index++
            //     }
            // }, 2000); 
            
            // const randTxt = document.querySelectorAll('.rand-txt');
            // const numTextRandom = randTxt.length;

            
            // var index = 0;
            // randTxt[0].style.top = '100%';
            // setInterval(function () {
            //     document.querySelector('.rand-txt-' + (index)).style.top = '-100%';
            //     if(index == numTextRandom){
            //         index = 0
            //     }else{
            //         index++
            //     }
                
            // }, 2000); 

            // function animateTextScroll() {
            //     text_rand.style.transform = `translateY(-${index * textScrollHeight}px)`;
            //     index = (index + 1) % textScrollItems.length; 
            // }

            // setInterval(animateTextScroll, 3000); 
        },
		galaxy() {
			"use strict";

			var canvas = document.getElementById('canvas_galaxy'),
				ctx = canvas.getContext('2d'),
				w = canvas.width = window.innerWidth,
				h = canvas.height = window.innerHeight,

				hue = 217,
				stars = [],
				count = 0,
				maxStars = 1400;

			// Thanks @jackrugile for the performance tip! https://codepen.io/jackrugile/pen/BjBGoM
			// Cache gradient
			var canvas2 = document.createElement('canvas'),
				ctx2 = canvas2.getContext('2d');
			canvas2.width = 100;
			canvas2.height = 100;
			var half = canvas2.width / 2,
				gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);
			gradient2.addColorStop(0.025, '#fff');
			gradient2.addColorStop(0.1, 'hsl(' + hue + ', 61%, 33%)');
			gradient2.addColorStop(0.25, 'hsl(' + hue + ', 64%, 6%)');
			gradient2.addColorStop(1, 'transparent');

			ctx2.fillStyle = gradient2;
			ctx2.beginPath();
			ctx2.arc(half, half, half, 0, Math.PI * 2);
			ctx2.fill();

			// End cache

			function random(min, max) {
				if (arguments.length < 2) {
					max = min;
					min = 0;
				}

				if (min > max) {
					var hold = max;
					max = min;
					min = hold;
				}

				return Math.floor(Math.random() * (max - min + 1)) + min;
			}

			function maxOrbit(x, y) {
				var max = Math.max(x, y),
					diameter = Math.round(Math.sqrt(max * max + max * max));
				return diameter / 2;
			}

			var Star = function () {

				this.orbitRadius = random(maxOrbit(w, h));
				this.radius = random(60, this.orbitRadius) / 18;
				this.orbitX = w / 2;
				this.orbitY = h / 2;
				this.timePassed = random(0, maxStars);
				this.speed = random(this.orbitRadius) / 1500000;
				this.alpha = random(2, 10) / 10;

				count++;
				stars[count] = this;
			}

			Star.prototype.draw = function () {
				var x = Math.sin(this.timePassed) * this.orbitRadius + this.orbitX,
					y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY,
					twinkle = random(10);

				if (twinkle === 1 && this.alpha > 0) {
					this.alpha -= 0.05;
				} else if (twinkle === 2 && this.alpha < 1) {
					this.alpha += 0.05;
				}

				ctx.globalAlpha = this.alpha;
				ctx.drawImage(canvas2, x - this.radius / 2, y - this.radius / 2, this.radius, this.radius);
				this.timePassed += this.speed;
			}

			for (var i = 0; i < maxStars; i++) {
				new Star();
			}

			function animation() {
				ctx.globalCompositeOperation = 'source-over';
				ctx.globalAlpha = 0.8;
				ctx.fillStyle = 'hsla(' + hue + ', 0%, 6%, 1)';
				ctx.fillRect(0, 0, w, h)

				ctx.globalCompositeOperation = 'lighter';
				for (var i = 1, l = stars.length; i < l; i++) {
					stars[i].draw();
				};

				window.requestAnimationFrame(animation);
			}

			animation();
		}


	},
	// beforeDestroy() {
	//   window.removeEventListener('resize', this.setBackgroundImage);
	// },
};
</script>

<style>
.first-cont {min-height: 100vh;}
.sec-mainbanner {max-height: 100vh; position: relative; display: flex; align-items: center; overflow: hidden;}
.sec-mainbanner .container-fluid .sec-contain { padding-bottom: 55vw;}

.randomtext.show{opacity: 1;}
#orbit-system {position: absolute;width: 50vw;height: 50vw;margin: 0 auto;top: -25vw; right: 2vw;}
.system {position: relative;width: 100%;height: 100%;-webkit-transform: rotateX(75deg) rotateY(-10deg);transform: rotateX(75deg) rotateY(-10deg);-webkit-transform-style: preserve-3d;transform-style: preserve-3d;}
.planet,
.satellite-orbit,
.satellite,.satellite {position: absolute;top: 50%;left: 50%;-webkit-transform-style: preserve-3d;transform-style: preserve-3d;pointer-events: none;}
.planet {width: 50vw;height: 50vw;margin-top: -25vw;margin-left: -23vw;border-radius: 50%;color: white;text-align: center;line-height: 9em;-webkit-transform: rotateY(10deg) rotateX(-75deg);transform: rotateY(10deg) rotateX(-75deg);}
.satellite-orbit {width: 50vw;height: 50vw;margin-top: -25vw;margin-left: -25vw;border-radius: 50%;animation-name: orbit;animation-duration: 10s;animation-iteration-count: infinite;animation-timing-function: linear;}
.satellite {top: 100%;width: 4em;height: 4em;margin-top: -2em;margin-left: -2em;color: black;border-radius: 50%;text-align: center;line-height: 3em;-webkit-animation-name: invert-orbit;animation-name: invert-orbit;-webkit-animation-duration: 10s;animation-duration: 10s;-webkit-animation-iteration-count: infinite;animation-iteration-count: infinite;-webkit-animation-timing-function: linear;animation-timing-function: linear;}
.satellite:nth-of-type(2) {-webkit-animation-name: invert-orbit;animation-name: invert-orbit;-webkit-animation-duration: 20s;animation-duration: 20s;-webkit-animation-iteration-count: infinite;animation-iteration-count: infinite;-webkit-animation-timing-function: linear;animation-timing-function: linear;}
.system-radious { position: absolute; width: 100%; height: 100%; border: 2px solid rgba(255,255,255,0.5); border-radius:100% ; opacity:1;filter: blur(3px);}
.system-radious-2 {width: 140%;height: 150%;left: -20%;top: -25%; opacity:1; filter: blur(4px);}

.randomtext { margin-top: 0.5vw; overflow: visible;}
.rand-blank { position: relative; opacity: 1;}
.rand-txt { position: absolute; top:0; left: 0; width: 100%; transition: all ease 2s; }

@-webkit-keyframes orbit {
    0% {transform: rotateZ(0deg);}
	100% {transform: rotateZ(360deg);}
}

@keyframes orbit {
	0% {transform: rotateZ(0deg);}
	100% {transform: rotateZ(360deg);}
}

@-webkit-keyframes invert-orbit {
	0% {transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);}
	100% {transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);}
}

@keyframes invert-orbit {
	0% {transform: rotateZ(360deg) rotateY(30deg) rotateX(-75deg);}
	100% {transform: rotateZ(0deg) rotateY(30deg) rotateX(-75deg);}
}
.randomtext {position: relative;}
.text-scroll-loop {width: 100%;float: left;   transition:all ease .5s;}
.text-scroll-loop span{max-height: 85px; width: 100%; float: left; line-height: inherit; padding-top: 0.05em;}

#canvas_galaxy {position:absolute;top: 0;left: 0;transform-style: preserve-3d;opacity: 0.5;}

.bg-blend-img {transform-style: preserve-3d;position:absolute;top: 0;left: 0;width: 100%;height: 100%;background: url(@/assets/image/home/bg-home-banner-d.jpg) no-repeat top right; background-size: cover;mix-blend-mode: lighten;opacity: 1;}
.bg-blend-img:before { content: ""; position: absolute; bottom: 0; left: 0; width: 100%; height: 30%; background: rgb(0,0,0);background: linear-gradient(0deg, rgba(0,0,0,1) 13%, rgba(0,0,0,0) 100%);}

#sticky-section {top: 0;transform-style: preserve-3d;}
#first-cont {width: 100%;height: 100%;overflow: hidden;}
#sticky-outer {height: 100vh;padding: 100px 0;}
#sticky-outer[data-scroll-sticky="true"] {position: fixed;height: 100vh;width: 100%;}
#box-green {background: #000;height: 100vh;position: fixed;width: 100%;z-index: -1;overflow: hidden;}
#box-green[data-scroll-sticky="true"] {position: fixed;top: 0;}
.scrollable-inner {width: 100%;position: relative;padding-top: 50vh;}
#sticky-section.sticky {background-color: red;}
.text-loop {width: 100%;position: relative;margin-top: 50px;}
.scrolling-text {position: relative;opacity: 0.5;width: 1140vw;font-weight: 700;float: left;color: #000;font-size: 12vw;line-height: .85;text-transform: uppercase;-webkit-text-stroke: 1px #02FFB3;text-stroke: 1px #02FFB3;-webkit-text-fill-color: transparent;text-fill-color: transparent;color: #0000;-webkit-text-stroke-width: 1px;}
.scrolling-text:last-child::before {content: "";position: absolute;width: 100%;height: 100%;bottom: 0;left: 0;background: rgb(0, 0, 0);background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);z-index: 1;}
.rectangle {width: 100%;height: 100%;position: absolute;top: 0;left: 0;}
.rectangle span {background: url('../assets/image/skin/rectangle-wave.svg');transform-origin: center center;position: absolute;width: 100%;height: 100%;left: 50%;top: 50%;z-index: 1;background-repeat: no-repeat;background-size: cover;transform: translate(-50%, -50%);opacity: .3;}

.scrollbar-track.show, .scrollbar-track:hover{opacity: 0;display: none !important;}
/* .rectangle span{animation:rectangle 9s  infinite; } */
/* .rectangle span:nth-of-type(2){animation:rectangle 20s  infinite; }
.rectangle span:nth-of-type(3){animation:rectangle 30s  infinite; } */
@keyframes rectangle {
    0% {	width: 100%;	height: 100%;opacity: 0.3;}
	100% {	width: 400%;	height: 400%;	opacity: 0;}
}


@media (max-width: 1199.98px) {
	.scrollable-inner {
		padding-top: 30vh
	}
}

@media (max-width: 991.98px) {
	
    .st.sec-mainbanner .cont-img {width:100%; height: calc(75vw - 30px); padding:0;}
    #orbit-system { width: 100%; height: calc(100vw - 30px); top: -10vw; right: 0;}
    .satellite-orbit {width: 100vw;height: 100vw;margin-top: -50vw;margin-left: -50vw; }
    .satellite { width:5vw ; height: 5vw; margin-left: -2.5vw;margin-top: -2.5vw;}
    .sec-mainbanner .container-fluid .sec-contain { padding: 50px 0;}
    .planet { width: 90vw;height: 90vw;margin-top: -45vw;margin-left: -43vw;}
    .contact-footer-cont {transform: translateY(0);}

    .st.sec-mainbanner .container-fluid .cont-txt { padding-left: 22px; padding-right: 22px;}
}

@media (max-width: 767.98px) {

    .sec-mainbanner.st .sec-contain.pd { padding-top: 0;}
    #orbit-system { height: 70vw; top: 0;}
    .st.sec-mainbanner .cont-img { height: 70vw;}
	.scrolling-text {font-size: 10em;width: 10000vw;}
	.st.sec-mainbanner .container-fluid .cont-txt { padding-left: 0; padding-right: 0px;}
    .first-cont { min-height: auto;}
    .st.scrollable-inner { padding-top: 220px;}

    .st.sec-mainbanner .container-fluid .cont-txt .box-oversize h1 { line-height: 1.2; }
	.satellite-orbit .satellite:last-child{display: none;}
}

.color-tranparent{color: transparent;}
.headline-group { position: relative; overflow: hidden; display: block;}
.headline-group .position-absolute { left: 0; top: 0;transform:skewY(0deg); }
</style>
