<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="fw overflow-hidden">
    <div class="cursor"></div>
  <div class="cursor2"></div>
  <div class="loadpage animate__animated   show">
    <img class="up-down" src="@/assets/image/about/cat.png" alt="">
  </div>
  <!-- <div class="intro d-none">
    <svg width="1920" height="100%" viewBox="0 0 1920 1137" class="nexstep nexstep-2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M377.34 491C390.1 491 401.467 493.127 411.44 497.38C421.413 501.633 429.847 507.353 436.74 514.54C443.633 521.58 448.84 529.793 452.36 539.18C456.027 548.42 457.86 558.027 457.86 568C457.86 577.973 456.027 587.653 452.36 597.04C448.84 606.28 443.633 614.493 436.74 621.68C429.993 628.72 421.633 634.367 411.66 638.62C401.687 642.873 390.247 645 377.34 645H314.42V491H377.34ZM360.18 529.72V606.28H374.04C379.76 606.28 384.893 605.253 389.44 603.2C394.133 601 398.02 598.14 401.1 594.62C404.327 591.1 406.747 587.067 408.36 582.52C410.12 577.827 411 572.987 411 568C411 563.013 410.12 558.247 408.36 553.7C406.747 549.007 404.327 544.9 401.1 541.38C398.02 537.86 394.133 535.073 389.44 533.02C384.893 530.82 379.76 529.72 374.04 529.72H360.18Z" fill="black" class="svg-elem-1"></path>
      <path d="M477.42 491H523.62V645H477.42V491Z" fill="black" class="svg-elem-2"></path>
      <path d="M631.72 559.64H718.4V589.78H703.66C702.193 598.58 699.407 606.647 695.3 613.98C691.34 621.313 686.133 627.62 679.68 632.9C673.227 638.033 665.453 642.067 656.36 645C647.413 647.787 637.293 649.18 626 649.18C612.8 649.18 601.067 647.053 590.8 642.8C580.68 638.4 572.1 632.533 565.06 625.2C558.167 617.867 552.887 609.287 549.22 599.46C545.7 589.487 543.94 579 543.94 568C543.94 557.44 545.773 547.32 549.44 537.64C553.107 527.813 558.46 519.16 565.5 511.68C572.54 504.053 581.12 498.04 591.24 493.64C601.36 489.093 612.873 486.82 625.78 486.82C636.927 486.82 647.047 488.36 656.14 491.44C665.233 494.52 673.08 498.7 679.68 503.98C686.28 509.26 691.487 515.42 695.3 522.46C699.26 529.353 701.607 536.687 702.34 544.46H651.74C651.3 542.26 650.42 540.06 649.1 537.86C647.927 535.66 646.24 533.68 644.04 531.92C641.84 530.16 639.127 528.767 635.9 527.74C632.82 526.567 629.3 525.98 625.34 525.98C619.18 525.98 613.9 527.227 609.5 529.72C605.1 532.067 601.507 535.22 598.72 539.18C595.933 543.14 593.88 547.687 592.56 552.82C591.24 557.953 590.58 563.233 590.58 568.66C590.58 574.673 591.313 580.32 592.78 585.6C594.393 590.733 596.74 595.28 599.82 599.24C603.047 603.053 607.007 606.06 611.7 608.26C616.393 610.46 621.893 611.56 628.2 611.56C636.853 611.56 643.527 609.507 648.22 605.4C653.06 601.147 656.067 595.94 657.24 589.78H631.72V559.64Z" fill="black" class="svg-elem-3"></path>
      <path d="M731.42 491H777.62V645H731.42V491Z" fill="black" class="svg-elem-4"></path>
      <path d="M828.08 530.16H796.4V491H905.52V530.16H873.84V645H828.08V530.16Z" fill="black" class="svg-elem-5"></path>
      <path d="M1008.64 645L1003.36 627.18H951.66L946.38 645H896.88L953.64 491H1001.6L1059.68 645H1008.64ZM977.62 537.2L961.34 593.96H993.9L977.62 537.2Z" fill="black" class="svg-elem-6"></path>
      <path d="M1160.44 605.84V645H1074.42V491H1120.18V605.84H1160.44Z" fill="black" class="svg-elem-7"></path>
      <path d="M1275.22 582.3V491H1321.2V645H1274.56L1224.18 556.78V645H1178.42V491H1225.06L1275.22 582.3Z" fill="black" class="svg-elem-8"></path>
      <path d="M1394.18 525.32V552.16H1447.64V584.94H1394.18V610.68H1447.64V645H1348.42V491H1447.64V525.32H1394.18Z" fill="black" class="svg-elem-9"></path>
      <path d="M1554.38 491H1604.32L1558.56 568L1604.32 645H1554.38L1532.16 601L1509.94 645H1459.78L1505.54 568L1459.78 491H1509.94L1532.16 535L1554.38 491Z" fill="black" class="svg-elem-10"></path>
    </svg>
    <svg width="1920" height="100%" viewBox="0 0 1920 1137" class="nexstep nexstep-3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M377.34 491C390.1 491 401.467 493.127 411.44 497.38C421.413 501.633 429.847 507.353 436.74 514.54C443.633 521.58 448.84 529.793 452.36 539.18C456.027 548.42 457.86 558.027 457.86 568C457.86 577.973 456.027 587.653 452.36 597.04C448.84 606.28 443.633 614.493 436.74 621.68C429.993 628.72 421.633 634.367 411.66 638.62C401.687 642.873 390.247 645 377.34 645H314.42V491H377.34ZM360.18 529.72V606.28H374.04C379.76 606.28 384.893 605.253 389.44 603.2C394.133 601 398.02 598.14 401.1 594.62C404.327 591.1 406.747 587.067 408.36 582.52C410.12 577.827 411 572.987 411 568C411 563.013 410.12 558.247 408.36 553.7C406.747 549.007 404.327 544.9 401.1 541.38C398.02 537.86 394.133 535.073 389.44 533.02C384.893 530.82 379.76 529.72 374.04 529.72H360.18Z" fill="black" class="svg-elem-1"></path>
      <path d="M477.42 491H523.62V645H477.42V491Z" fill="black" class="svg-elem-2"></path>
      <path d="M631.72 559.64H718.4V589.78H703.66C702.193 598.58 699.407 606.647 695.3 613.98C691.34 621.313 686.133 627.62 679.68 632.9C673.227 638.033 665.453 642.067 656.36 645C647.413 647.787 637.293 649.18 626 649.18C612.8 649.18 601.067 647.053 590.8 642.8C580.68 638.4 572.1 632.533 565.06 625.2C558.167 617.867 552.887 609.287 549.22 599.46C545.7 589.487 543.94 579 543.94 568C543.94 557.44 545.773 547.32 549.44 537.64C553.107 527.813 558.46 519.16 565.5 511.68C572.54 504.053 581.12 498.04 591.24 493.64C601.36 489.093 612.873 486.82 625.78 486.82C636.927 486.82 647.047 488.36 656.14 491.44C665.233 494.52 673.08 498.7 679.68 503.98C686.28 509.26 691.487 515.42 695.3 522.46C699.26 529.353 701.607 536.687 702.34 544.46H651.74C651.3 542.26 650.42 540.06 649.1 537.86C647.927 535.66 646.24 533.68 644.04 531.92C641.84 530.16 639.127 528.767 635.9 527.74C632.82 526.567 629.3 525.98 625.34 525.98C619.18 525.98 613.9 527.227 609.5 529.72C605.1 532.067 601.507 535.22 598.72 539.18C595.933 543.14 593.88 547.687 592.56 552.82C591.24 557.953 590.58 563.233 590.58 568.66C590.58 574.673 591.313 580.32 592.78 585.6C594.393 590.733 596.74 595.28 599.82 599.24C603.047 603.053 607.007 606.06 611.7 608.26C616.393 610.46 621.893 611.56 628.2 611.56C636.853 611.56 643.527 609.507 648.22 605.4C653.06 601.147 656.067 595.94 657.24 589.78H631.72V559.64Z" fill="black" class="svg-elem-3"></path>
      <path d="M731.42 491H777.62V645H731.42V491Z" fill="black" class="svg-elem-4"></path>
      <path d="M828.08 530.16H796.4V491H905.52V530.16H873.84V645H828.08V530.16Z" fill="black" class="svg-elem-5"></path>
      <path d="M1008.64 645L1003.36 627.18H951.66L946.38 645H896.88L953.64 491H1001.6L1059.68 645H1008.64ZM977.62 537.2L961.34 593.96H993.9L977.62 537.2Z" fill="black" class="svg-elem-6"></path>
      <path d="M1160.44 605.84V645H1074.42V491H1120.18V605.84H1160.44Z" fill="black" class="svg-elem-7"></path>
      <path d="M1275.22 582.3V491H1321.2V645H1274.56L1224.18 556.78V645H1178.42V491H1225.06L1275.22 582.3Z" fill="black" class="svg-elem-8"></path>/
      <path d="M1394.18 525.32V552.16H1447.64V584.94H1394.18V610.68H1447.64V645H1348.42V491H1447.64V525.32H1394.18Z" fill="black" class="svg-elem-9"></path>
      <path d="M1554.38 491H1604.32L1558.56 568L1604.32 645H1554.38L1532.16 601L1509.94 645H1459.78L1505.54 568L1459.78 491H1509.94L1532.16 535L1554.38 491Z" fill="black" class="svg-elem-10"></path>
    </svg>
    <svg width="1920" height="100%" viewBox="0 0 1920 1137" class="nexstep nexstep-4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M377.34 491C390.1 491 401.467 493.127 411.44 497.38C421.413 501.633 429.847 507.353 436.74 514.54C443.633 521.58 448.84 529.793 452.36 539.18C456.027 548.42 457.86 558.027 457.86 568C457.86 577.973 456.027 587.653 452.36 597.04C448.84 606.28 443.633 614.493 436.74 621.68C429.993 628.72 421.633 634.367 411.66 638.62C401.687 642.873 390.247 645 377.34 645H314.42V491H377.34ZM360.18 529.72V606.28H374.04C379.76 606.28 384.893 605.253 389.44 603.2C394.133 601 398.02 598.14 401.1 594.62C404.327 591.1 406.747 587.067 408.36 582.52C410.12 577.827 411 572.987 411 568C411 563.013 410.12 558.247 408.36 553.7C406.747 549.007 404.327 544.9 401.1 541.38C398.02 537.86 394.133 535.073 389.44 533.02C384.893 530.82 379.76 529.72 374.04 529.72H360.18Z" fill="black" class="svg-elem-1"></path>
      <path d="M477.42 491H523.62V645H477.42V491Z" fill="black" class="svg-elem-2"></path>
      <path d="M631.72 559.64H718.4V589.78H703.66C702.193 598.58 699.407 606.647 695.3 613.98C691.34 621.313 686.133 627.62 679.68 632.9C673.227 638.033 665.453 642.067 656.36 645C647.413 647.787 637.293 649.18 626 649.18C612.8 649.18 601.067 647.053 590.8 642.8C580.68 638.4 572.1 632.533 565.06 625.2C558.167 617.867 552.887 609.287 549.22 599.46C545.7 589.487 543.94 579 543.94 568C543.94 557.44 545.773 547.32 549.44 537.64C553.107 527.813 558.46 519.16 565.5 511.68C572.54 504.053 581.12 498.04 591.24 493.64C601.36 489.093 612.873 486.82 625.78 486.82C636.927 486.82 647.047 488.36 656.14 491.44C665.233 494.52 673.08 498.7 679.68 503.98C686.28 509.26 691.487 515.42 695.3 522.46C699.26 529.353 701.607 536.687 702.34 544.46H651.74C651.3 542.26 650.42 540.06 649.1 537.86C647.927 535.66 646.24 533.68 644.04 531.92C641.84 530.16 639.127 528.767 635.9 527.74C632.82 526.567 629.3 525.98 625.34 525.98C619.18 525.98 613.9 527.227 609.5 529.72C605.1 532.067 601.507 535.22 598.72 539.18C595.933 543.14 593.88 547.687 592.56 552.82C591.24 557.953 590.58 563.233 590.58 568.66C590.58 574.673 591.313 580.32 592.78 585.6C594.393 590.733 596.74 595.28 599.82 599.24C603.047 603.053 607.007 606.06 611.7 608.26C616.393 610.46 621.893 611.56 628.2 611.56C636.853 611.56 643.527 609.507 648.22 605.4C653.06 601.147 656.067 595.94 657.24 589.78H631.72V559.64Z" fill="black" class="svg-elem-3"></path>
      <path d="M731.42 491H777.62V645H731.42V491Z" fill="black" class="svg-elem-4"></path>
      <path d="M828.08 530.16H796.4V491H905.52V530.16H873.84V645H828.08V530.16Z" fill="black" class="svg-elem-5"></path>
      <path d="M1008.64 645L1003.36 627.18H951.66L946.38 645H896.88L953.64 491H1001.6L1059.68 645H1008.64ZM977.62 537.2L961.34 593.96H993.9L977.62 537.2Z" fill="black" class="svg-elem-6"></path>
      <path d="M1160.44 605.84V645H1074.42V491H1120.18V605.84H1160.44Z" fill="black" class="svg-elem-7"></path>
      <path d="M1275.22 582.3V491H1321.2V645H1274.56L1224.18 556.78V645H1178.42V491H1225.06L1275.22 582.3Z" fill="black" class="svg-elem-8"></path>
      <path d="M1394.18 525.32V552.16H1447.64V584.94H1394.18V610.68H1447.64V645H1348.42V491H1447.64V525.32H1394.18Z" fill="black" class="svg-elem-9"></path>
      <path d="M1554.38 491H1604.32L1558.56 568L1604.32 645H1554.38L1532.16 601L1509.94 645H1459.78L1505.54 568L1459.78 491H1509.94L1532.16 535L1554.38 491Z" fill="black" class="svg-elem-10"></path>
    </svg>
    <svg width="1920" height="100%" viewBox="0 0 1920 1137" class="nexstep nexstep-5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M377.34 491C390.1 491 401.467 493.127 411.44 497.38C421.413 501.633 429.847 507.353 436.74 514.54C443.633 521.58 448.84 529.793 452.36 539.18C456.027 548.42 457.86 558.027 457.86 568C457.86 577.973 456.027 587.653 452.36 597.04C448.84 606.28 443.633 614.493 436.74 621.68C429.993 628.72 421.633 634.367 411.66 638.62C401.687 642.873 390.247 645 377.34 645H314.42V491H377.34ZM360.18 529.72V606.28H374.04C379.76 606.28 384.893 605.253 389.44 603.2C394.133 601 398.02 598.14 401.1 594.62C404.327 591.1 406.747 587.067 408.36 582.52C410.12 577.827 411 572.987 411 568C411 563.013 410.12 558.247 408.36 553.7C406.747 549.007 404.327 544.9 401.1 541.38C398.02 537.86 394.133 535.073 389.44 533.02C384.893 530.82 379.76 529.72 374.04 529.72H360.18Z" fill="black" class="svg-elem-1"></path>
      <path d="M477.42 491H523.62V645H477.42V491Z" fill="black" class="svg-elem-2"></path>
      <path d="M631.72 559.64H718.4V589.78H703.66C702.193 598.58 699.407 606.647 695.3 613.98C691.34 621.313 686.133 627.62 679.68 632.9C673.227 638.033 665.453 642.067 656.36 645C647.413 647.787 637.293 649.18 626 649.18C612.8 649.18 601.067 647.053 590.8 642.8C580.68 638.4 572.1 632.533 565.06 625.2C558.167 617.867 552.887 609.287 549.22 599.46C545.7 589.487 543.94 579 543.94 568C543.94 557.44 545.773 547.32 549.44 537.64C553.107 527.813 558.46 519.16 565.5 511.68C572.54 504.053 581.12 498.04 591.24 493.64C601.36 489.093 612.873 486.82 625.78 486.82C636.927 486.82 647.047 488.36 656.14 491.44C665.233 494.52 673.08 498.7 679.68 503.98C686.28 509.26 691.487 515.42 695.3 522.46C699.26 529.353 701.607 536.687 702.34 544.46H651.74C651.3 542.26 650.42 540.06 649.1 537.86C647.927 535.66 646.24 533.68 644.04 531.92C641.84 530.16 639.127 528.767 635.9 527.74C632.82 526.567 629.3 525.98 625.34 525.98C619.18 525.98 613.9 527.227 609.5 529.72C605.1 532.067 601.507 535.22 598.72 539.18C595.933 543.14 593.88 547.687 592.56 552.82C591.24 557.953 590.58 563.233 590.58 568.66C590.58 574.673 591.313 580.32 592.78 585.6C594.393 590.733 596.74 595.28 599.82 599.24C603.047 603.053 607.007 606.06 611.7 608.26C616.393 610.46 621.893 611.56 628.2 611.56C636.853 611.56 643.527 609.507 648.22 605.4C653.06 601.147 656.067 595.94 657.24 589.78H631.72V559.64Z" fill="black" class="svg-elem-3"></path>
      <path d="M731.42 491H777.62V645H731.42V491Z" fill="black" class="svg-elem-4"></path>
      <path d="M828.08 530.16H796.4V491H905.52V530.16H873.84V645H828.08V530.16Z" fill="black" class="svg-elem-5"></path>
      <path d="M1008.64 645L1003.36 627.18H951.66L946.38 645H896.88L953.64 491H1001.6L1059.68 645H1008.64ZM977.62 537.2L961.34 593.96H993.9L977.62 537.2Z" fill="black" class="svg-elem-6"></path>
      <path d="M1160.44 605.84V645H1074.42V491H1120.18V605.84H1160.44Z" fill="black" class="svg-elem-7"></path>
      <path d="M1275.22 582.3V491H1321.2V645H1274.56L1224.18 556.78V645H1178.42V491H1225.06L1275.22 582.3Z" fill="black" class="svg-elem-8"></path>
      <path d="M1394.18 525.32V552.16H1447.64V584.94H1394.18V610.68H1447.64V645H1348.42V491H1447.64V525.32H1394.18Z" fill="black" class="svg-elem-9"></path>
      <path d="M1554.38 491H1604.32L1558.56 568L1604.32 645H1554.38L1532.16 601L1509.94 645H1459.78L1505.54 568L1459.78 491H1509.94L1532.16 535L1554.38 491Z" fill="black" class="svg-elem-10"></path>
    </svg>
  </div> -->
  <NavBar />
 
  </div>
 
  <router-view/>


</template>
<script>
 /* eslint-disable */
 import { setFunction } from "@/ui";
import NavBar  from "./components/NavBar.vue";

  export default{
    data() {
      return {
        svgEle : '.svg-elem-',
        
      }
    },
    components: {
      NavBar
    },
    mounted(){
     
      window.addEventListener('load', () =>{

     

        setTimeout(() => {
          document.querySelector(".loadpage").classList.add('animate__fadeOut');
          document.querySelector('body').removeAttribute('style');
          setTimeout(() => {
            document.querySelector(".loadpage").style.display = "none";
          }, 1000);
        }, 1000);

        setFunction();
         const intro = document.querySelector('.intro');

        // var wrapper = document.querySelector('.intro svg')
        // function draw() {
        //   wrapper.classList.add('active');
        // }

        // window.addEventListener('click', draw, false)
        // // setTimeout(draw , 300);
        
        // setTimeout(function(){
        // draw();
        //   if(intro.classList.contains('active')){
        //     setTimeout(() => {
        //       intro.classList.add('fade-out');
        //     }, 500);
        //   } else{
            
        //   }
        // }, 300);
        var currentStep = 1;
        var nextSetp = 1;
        setTimeout(() => {
              // intro.style.backgroundColor = "#000";
             
              const elements = document.querySelectorAll('.intro svg path');

              elements.forEach((element , index) => {

                  const randomDelay = Math.floor(Math.random() * 500);
                  setTimeout(() => {
                    element.style.fill = "#fff";

                    currentStep = index;

                    if (index === elements.length - 1) {
                      nextSetp = 2
                      if ( nextSetp == 2) {
                       setTimeout(() => {
                        intro.style.backgroundColor = "#000";
                          setTimeout(() => {
                            for (let innerIndex = 0; innerIndex < elements.length; innerIndex++) {
                              elements[innerIndex].style.fill = "#01FFAB";
                                if (innerIndex === elements.length - 1) {
                                  setTimeout(() => {
                                      const nexstep = document.querySelectorAll('.intro svg.nexstep');
                                      nexstep.forEach(element => {
                                        element.style.transform = "translateY(-40%)";
                                          setTimeout(() => {
                                            element.classList.add('active');

                                            setTimeout(() => {
                                              intro.style.backgroundColor = "#002200";
                                              //const nexstep2 = document.querySelectorAll('.intro svg.nexstep');

                                              // nexstep2.forEach(nexstep2 => {
                                              //       nexstep2.classList.remove('active');
                                              //       nexstep2.classList.add('active-2');
                                              //       setTimeout(() => {
                                              //         document.querySelector('.intro svg.nexstep-2').classList.add('rotate');
                                              //         setTimeout(() => {
                                              //           document.querySelector('.intro svg.nexstep-2').classList.add('scale');
                                              //           setTimeout(() => {
                                              //             document.querySelector('.intro svg.nexstep-2').classList.add('transform');
                                              //               setTimeout(() => {
                                              //                 document.querySelector('.intro svg.nexstep-2').classList.add('scale-down');
                                              //                 document.querySelector('.intro').classList.add('fade-out');
                                              //               }, 1000);
                                              //           }, 1000);
                                              //         }, 1000);
                                              //       }, 1200);
                                              //   });
                                            }, 1000);
                                          },500)
                                      });
                                  }, 1000);
                                }
                            }
                          }, 100);
                       }, 1000);
                      }
                    }
                  }, randomDelay);

                
                

              });

              
              
               
        }, 500);
      });

      var cursor = document.querySelector('.cursor');
        var cursorinner = document.querySelector('.cursor2');
        var a = document.querySelectorAll('a');

        document.addEventListener('mousemove', function(e){
            var x = e.clientX;
            var y = e.clientY;
            cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
        });

        document.addEventListener('mousemove', function(e){
            var x = e.clientX;
            var y = e.clientY;
            cursorinner.style.left = x + 'px';
            cursorinner.style.top = y + 'px';
        });

        document.addEventListener('mousedown', function(){
            cursor.classList.add('click');
            cursorinner.classList.add('cursorinnerhover')
        });

        document.addEventListener('mouseup', function(){
            cursor.classList.remove('click')
            cursorinner.classList.remove('cursorinnerhover')
        });

        a.forEach(item => {
            item.addEventListener('mouseover', () => {
                cursor.classList.add('hover');
            });
            item.addEventListener('mouseleave', () => {
                cursor.classList.remove('hover');
            });
        })

    },
    
   
   
  }
</script>
<style >
/* .loadpage{position: fixed;width: 100%;height: 100%;left: 0;top: 0;background-color: #2c3e50;z-index:9999 ;} */
#app {-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
#next-cont{overflow: hidden;}
nav {padding: 30px;}

nav a {font-weight: bold;color: #2c3e50;}


nav a.router-link-exact-active {color: #42b983;}
.intro{ width: 100%;height: 100%;display: flex;align-items: center;background: #000;color: #000;position: fixed;z-index: 999999;top: 0;left: 0;transition: all ease-out .5s;}
.fade-out{opacity: 0; pointer-events: none;}

.loadpage{ width: 100%;height: 100%;display: flex;align-items: center;background: #000;color: #000;position: fixed;z-index: 999999;top: 0;left: 0;justify-content: center;}
.loadpage img{height: 130px;}

.up-down{
  animation: UpDown linear 2s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: UpDown linear 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: UpDown linear 2s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: UpDown linear 2s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: UpDown linear 2s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

@keyframes UpDown {
  0% {
    transform:  translate(1px,0)  
  }
  50% {
    transform:  translate(1px,10px)  
  }
  100% {
    transform:  translate(1px,0)  
  }
}



 .intro svg{width: 100%;height: 100%;}
 svg .svg-elem-1 {
  fill: transparent;
  -webkit-transition: fill 1s 0.1s;
          transition: fill 1s 0.1s;
}

svg.active .svg-elem-1 {
  /* fill: #5E27FF; */
}

svg .svg-elem-2 {
  fill: transparent;
  -webkit-transition: fill 1s 0.2s;
          transition: fill 1s 0.2s;
}

svg.active .svg-elem-2 {
  /* fill: #5E27FF; */
}

svg .svg-elem-3 {
  fill: transparent;
  -webkit-transition: fill 1s 0.3s;
          transition: fill 1s 0.3s;
}

svg.active .svg-elem-3 {
  /* fill: #5E27FF; */
}

svg .svg-elem-4 {
  fill: transparent;
  -webkit-transition: fill 1s 0.4s;
          transition: fill 1s 0.4s;
}

svg.active .svg-elem-4 {
  /* fill: #5E27FF; */
}

svg .svg-elem-5 {
  fill: transparent;
  -webkit-transition: fill 1s 0.5s;
          transition: fill 1s 0.5s;
}

svg.active .svg-elem-5 {
  /* fill: #5E27FF; */
}

svg .svg-elem-6 {
  fill: transparent;
  -webkit-transition: fill 1s 0.6s;
          transition: fill 1s 0.6s;
}

svg.active .svg-elem-6 {
  /* fill: #5E27FF; */
}

svg .svg-elem-7 {
  fill: transparent;
  -webkit-transition: fill 1s 0.7s;
          transition: fill 1s 0.7s;
}

svg.active .svg-elem-7 {
  /* fill: #5E27FF; */
}

svg .svg-elem-8 {
  fill: transparent;
  -webkit-transition: fill 1s 0.8s;
          transition: fill 1s 0.8s;
}

svg.active .svg-elem-8 {
  /* fill: #5E27FF; */
}

svg .svg-elem-9 {
  fill: transparent;
  -webkit-transition: fill 1s 0.9s;
          transition: fill 1s 0.9s;
}

svg.active .svg-elem-9 {
  /* fill: #5E27FF; */
}

svg .svg-elem-10 {
  fill: transparent;
  -webkit-transition: fill 1s 1s;
          transition: fill 1s 1s;
}

svg.active .svg-elem-10 {
  /* fill: #5E27FF; */
}

.nexstep{position: fixed;transition: all ease-in .5s; }
.intro svg.nexstep-2{width: 100%;float: left; transition: all ease-in .5s;}
.intro svg.nexstep-3.active{transform: translateY(-15%) !important;transition: all ease-in .6s;}
.intro svg.nexstep-4.active{transform: translateY(10%) !important;transition: all ease-in .7s;}
.intro svg.nexstep-5.active{transform: translateY(35%) !important;transition: all ease-in .8s;}

.intro svg.nexstep.active-2{transform: translateY(0) !important; }
.intro svg.nexstep-2.active-2 path.svg-elem-6{opacity: 1 !important;transform-origin: center center;}
.intro svg.nexstep.active-2 path{fill: #fff !important;transition: .8s !important; opacity: 0;pointer-events: none;}
.intro svg.nexstep-2.active-2.animate-end path.svg-elem-6{opacity: 1;transform: translate(38%, 0%) rotate(29deg) scale(3);transform-origin: center; transition: none;pointer-events: all;}
.intro svg.nexstep-2.active-2.animate-end.fade path.svg-elem-6{transform: scale(0);}
.intro svg.nexstep.active-2.rotate path {
    transform-origin: center center;
    transform: rotate(23deg);
}
.intro svg.nexstep.active-2.rotate.scale path {
    transform: rotate(23deg) scale(3);
}

.intro svg.nexstep.active-2.rotate.scale.transform path {
    transform:translate(44%, 12%)  rotate(23deg) scale(3);
}
.intro svg.nexstep.active-2.rotate.scale.transform.scale-down path {
    transform:translate(44%, 12%)  rotate(23deg) scale(0);
}



</style>
