<template>
  <div class="st bg-nx-darkfade sec-full-service-digital pd pt-0">
    <div class="container container-1360">
        <div class="fw service-cont pd pt-0">
            <div class="box-spacialised">
                <img src="../../assets/image/home/spacialised-in-delivery.jpg" alt="" class="spacialised-in-delivery"/>
                <img src="../../assets/image/home/good-work.png" alt="" class="good-work"/>
            </div>
            <blockquote>
            <div class="box-content mb-md-5 mb-3">
                <h2 class=" text-uppercase text-light mb-2 mb-md-0"><b>a <span class="text-ctr-primary">Full-service </span> <span style="display: inline-block;">digital </span><br class="d-md-block d-none" /> creative <span style="display: inline-block;">agency</span></b></h2>
                <h4 class="text-light text-md">specialised in delivery<br class="d-md-block d-none" /> customer-driven experiences for
                    brands <br> with our one-stop service available.
                </h4>
            </div>
            <div class="row">
                <div class="col-12 col-md-3">
                    <div class="box-content mb-md-2 mb-4">
                        <h4 class="text-ctr-primary text-uppercase "><b>BRANDING</b></h4>
                        <ul class="text-light">
                            <li>Brand Planning</li>
                            <li>Communication Strategy</li>
                            <li>Platform Strategy</li>
                            <li>Internal Communication</li>
                            <li>Corporate Identity</li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="box-content mb-md-2 mb-4">
                        <h4 class="text-ctr-primary text-uppercase "><b>ADVERTISING</b></h4>
                        <ul class="text-light">
                            <li>Strategy and Planning</li>
                            <li>Media Planning</li>
                            <li>Marketing Campaign</li>
                            <li>Commercial Video</li>
                            <li>Visibilities</li>
                            <li>Print</li>
                            <li>Influencer Marketing</li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="box-content mb-md-2 mb-4">
                        <h4 class="text-ctr-primary text-uppercase "><b>DIGITAL SOLUTION</b></h4>
                        <ul class="text-light">
                            
                            <li>Social Media Management</li>
                            <li>Social Listening</li>
                            <li>Social Media Analytics</li>
                            <li>Online Brand Health Check</li>
                            <li>Media Planning and Buying</li>
                            <li>ROI Tracking</li>
                            <li>CRM</li>
                            <li>Digital Campaign</li>
                            <li>Thematic Campaign</li>
                            <li>Content Creation</li>
                            <li>Mobile Application</li>
                            <li>Web Development</li>
                            <li>E-Commerce Platform</li>
                            <li>SEM/SEO</li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="box-content mb-md-2 mb-4">
                        <h4 class="text-ctr-primary text-uppercase "><b>CREATIVE SOLUTION</b></h4>
                        <ul class="text-light">
                            <li>Packaging Design</li>
                            <li>Creative Work</li>
                            <li>Booklet</li>
                            <li>Marketing Materials</li>
                            <li>Video Presentation</li>
                            <li>Merchandise</li>
                            <li>Production House</li>
                            <li>Photography and Retouch</li>
                        </ul>
                    </div>
                </div>
            </div>
            </blockquote>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ContentService",
};
</script>

<style scoped>

.sec-full-service-digital{margin-top: 300px;}
  .box-spacialised{position: absolute;top: -5%;transform: translateY(-50%);  right: calc(50% - 50vw);width: 70%;}
   .good-work{width: 20%; position: absolute;right: 0;    margin-right: 10%;bottom: -30%;}
   .spacialised-in-delivery {border-radius: 20px 0 0 20px;}
   .service-cont ul{ margin-top: 10px;padding-bottom: 5px;}

   
   
   @media (max-width: 991.98px) {	
    .sec-full-service-digital{margin-top: 0;}
    .box-spacialised {position: relative;top: 0;transform: none;right: 0;margin-bottom: 40px;}
     .box-spacialised{width: 100%; margin-bottom: 0;}
     .spacialised-in-delivery {border-radius: 0;width:100vw; max-width: none;}
     .good-work { margin-right: 0;}
     .service-cont blockquote { padding-right: 0px;  }
     .service-cont { padding: 0 25px!important;}
   }
   @media (max-width: 767.98px) {	
    .service-cont { padding: 0!important;}
    .spacialised-in-delivery { margin-left: -20px; height: 45vw; object-fit: cover;}
   }
  
</style>
