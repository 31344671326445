    
    /* eslint-disable */

    export function setFunction() {
      let element = document.querySelector('body'); // Replace "myElement" with the ID of your element
        let computedStyle = window.getComputedStyle(element);
        let font = computedStyle.getPropertyValue("font-family");
        // document.querySelector('.font-system').innerHTML = font;


        

        let boxPalette = document.querySelectorAll('.color-palette');
        boxPalette.forEach(function(element) {
            let paletteValue = element.getAttribute('data-palette');

            let divColor = element.querySelectorAll('.get-color');
            let divBgColor = element.querySelectorAll('.get-bg');
            let divColorCode = element.querySelectorAll('.get-color-code');
            
            divColor.forEach(function(divColorelement) {
                divColorelement.setAttribute('data-txt-color', paletteValue);
            });

            divBgColor.forEach(function(divColorelement) {
                divColorelement.setAttribute('data-bg-color', 'nx-bg-'+paletteValue );
            });

        
           
            let colorCode = element.querySelector('h4');
            let paletteStyle = window.getComputedStyle(colorCode);
            let code = paletteStyle.getPropertyValue("background-color");
            element.querySelector('.color-code small').innerHTML = rgbToHex(code); 
            
            divColorCode.forEach(function(divColorelement) {
                divColorelement.setAttribute('data-color-code', rgbToHex(code));
            });
            
        });

        function rgbToHex(code) {
          
            let rgbArray = code.substring(4, code.length-1).split(',');
            let r = parseInt(rgbArray[0]);
            let g = parseInt(rgbArray[1]);
            let b = parseInt(rgbArray[2]);
        
            if (r.toString().length == 1)
                r = "0" + r;
            if (g.toString().length == 1)
                g = "0" + g;
            if (b.toString().length == 1)
                b = "0" + b;
                        
            return "#" + r.toString(16) + g.toString(16) + b.toString(16);
        }
        


       
        

        const copyButtons = document.querySelectorAll('.copy-clipboard');

      

        copyButtons.forEach(a => {
            // const tooltip = new bootstrap.Tooltip(a);
            let paletteValue = '';
            a.addEventListener('click', () => {
               
                console.log(a.getAttribute('data-color-code') != '');
                if (a.getAttribute('data-bg-color') != null) {
                    paletteValue = a.getAttribute('data-bg-color');
                } else if (a.getAttribute('data-txt-color') != null) {
                paletteValue = a.getAttribute('data-txt-color');
                }else if (a.getAttribute('data-color-code') != null) {
                    paletteValue = a.getAttribute('data-color-code');
                }
                // Create a temporary element to hold the text to be copied
                const tempElem = document.createElement('textarea');
                tempElem.value = paletteValue;
                document.body.appendChild(tempElem);

                // Select the text in the temporary element
                tempElem.select();

                // Copy the selected text to the clipboard
                document.execCommand('copy');

                // Remove the temporary element from the document
                document.body.removeChild(tempElem);

               // Add a "Copied!" message
                



               a.querySelector('.tip').classList.add('show');
                // Remove the "Copied!" message after 800ms
                setTimeout(function() {
                    a.querySelector('.tip').classList.remove('show');
                }, 800);
            });
        });


        function replaceSpecialChars(html) {
            // Regular expression to match special characters
            const specialCharsRegex = /[<>="'&]/g;
          
            // Replace special characters with elements with the class "sc"
            return html.replace(specialCharsRegex, (match) => {
              switch (match) {
                case '<':
                  return '<div class="sc">&lt;</div>';
                case '>':
                  return '<div class="sc">&gt;</div>';
                case '"':
                  return '<div class="sc">&quot;</div>';
                case "'":
                  return '<div class="sc">&#x27;</div>';
                case '=':
                  return '<div class="sc">&#x3D;</div>';
                case '&':
                  return '<div class="sc">&amp;</div>';
                default:
                  return match;
              }
            });
          }



          
  // Get all .html containers
  const htmlContainers = document.querySelectorAll('.html');

  // Iterate over each .html container
  htmlContainers.forEach(container => {
      // Get the .inhtml and .code-snippet containers
      const inhtmlContainer = container.querySelector('.inhtml');
      const codeSnippetContainer = container.querySelector('.code-snippet');
      
      
      // const  inhtmlContainerFormatte = inhtmlContainer.innerHTML.replace(/\s+/g, '')
      
      // Get the raw code
      const rawCode = inhtmlContainer.innerHTML.trim();
      // const rawCodeClean = rawCode.replace(/<\/button>\s+<button/g, '</button><button')
      const rawCodeClean = rawCode.replace(/\s+</g, '<');


      // Create a <code> element and set its content to the raw code
      const codeElement = document.createElement('code');
      codeElement.className = "language-html";
      codeElement.dataset.lang = "html";

      codeElement.textContent = rawCodeClean;

      // Create a <pre> element and append the <code> element to it
      const preElement = document.createElement('pre');

  
      preElement.appendChild(codeElement);

      
      
      // Append the <pre> element to the .code-snippet container
      codeSnippetContainer.appendChild(preElement);

      container.querySelectorAll('code').forEach(codeContainer =>{
       
         const addHtml = codeContainer.innerHTML.replace(/&gt;&lt;/g, '&gt;<br>&lt;');
           codeContainer.innerHTML = addHtml
      });
  });



  
  const htmlBtn = document.querySelectorAll('.btn-html-clipboard');
  const codeSnippet = document.querySelector('.code-snippet');
  htmlBtn.forEach(a => {
      a.addEventListener('click', () => {
          console.log('q');
          const contentHtml = codeSnippet.textContent;
          
          // Create a temporary textarea element to hold the HTML content
          const tempTextarea = document.createElement('textarea');
          tempTextarea.value = contentHtml;
          document.body.appendChild(tempTextarea);
          
          // Select and copy the content from the temporary textarea to the clipboard
          tempTextarea.select();
          document.execCommand('copy');
          
          // Remove the temporary textarea from the DOM
          document.body.removeChild(tempTextarea);
      });
  });



//   if ($('.tab-item').length > 0) {
//     $('.tab-item:first-child').show()
//   }
 
// $('.tab > ol > li').click(function(){
//     var thisItem = $(this).children().attr('childId');
//     if ($('.tab-item').length > 0) {
//         $('.tab-item').hide();
//         if ($('.tab-item').is(thisItem)) {
//             $(thisItem).show();
//         }
//     }
// });

// $('.has-sub > a').click(function(){
//    $(this).next('.sub-tab').slideToggle();
// });




/* ================================================ */
/* SET SVG */
/* ================================================ */
// svg();

// function svg() {

//     $('img.svg').each(function() {
//         var $img = $(this);
//         var imgID = $img.attr('id');
//         var imgClass = $img.attr('class');
//         var imgURL = $img.attr('src');

//         $.get(imgURL, function(data) {
//             // Get the SVG tag, ignore the rest
//             var $svg = $(data).find('svg');

//             // Add replaced image's ID to the new SVG
//             if (typeof imgID !== 'undefined') {
//                 $svg = $svg.attr('id', imgID);
//             }
//             // Add replaced image's classes to the new SVG
//             if (typeof imgClass !== 'undefined') {
//                 $svg = $svg.attr('class', imgClass + ' replaced-svg');
//             }

//             // Remove any invalid XML tags as per http://validator.w3.org
//             $svg = $svg.removeAttr('xmlns:a');

//             // Check if the viewport is set, else we gonna set it if we can.
//             if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
//                 $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
//             }

//             // Replace image with new SVG
//             $img.replaceWith($svg);

//         }, 'xml');

//     });
// }





/* ================================================ */
/* Init */
/* ================================================ */

// $('.selectpicker').selectpicker();
// $('.dropify').dropify();


/*
We want to preview images, so we need to register the Image Preview plugin
*/
// FilePond.registerPlugin(
	
// 	// encodes the file as base64 data
//     FilePondPluginFileEncode,
	
// 	// validates the size of the file
// 	FilePondPluginFileValidateSize,
	
// 	// corrects mobile image orientation
// 	FilePondPluginImageExifOrientation,
	
// 	// previews dropped images
//   FilePondPluginImagePreview
// );

// // Select the file input and use create() to turn it into a pond
// FilePond.create(
// 	document.querySelector('.filepond')
// );
    }

        