<template>
    <footer>
    <div class="st sec-footer">
      <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col col-12 col-md-12">
              <div class="box-content py-2 text-light text-center">
                <p class="m-0 text-md opacity-50"><small>© 2023 DigitalNEX co.,ltd . All Rights Reserved</small></p>
              </div>
            </div>
            <!-- <div class="col col-12 col-md-6">
              <div class="group-cont">
                <a class="btn-icon" href="tel:+0818191122"><span><img src="../assets/image/skin/icon-phone.svg" alt=""><span>0818191122</span></span></a>
                <a class="btn-icon"  href="mailto:Marketing@digitalnex.com"><span><img src="../assets/image/skin/icon-phone.svg" alt=""><span>Marketing@digitalnex.com</span></span></a>
                <div class="social">
                    <div class="so-txt">Follow us</div>
                    <a href=""><span><img src="../assets/image/skin/icon-facebook.svg" alt=""></span></a>
                    <a href=""><span><img src="../assets/image/skin/icon-twitter.svg" alt=""></span></a>
                    <a href=""><span><img src="../assets/image/skin/icon-youtube.svg" alt=""></span></a>
                    <a href=""><span><img src="../assets/image/skin/icon-linkin.svg" alt=""></span></a>
                    <a href=""><span><img src="../assets/image/skin/icon-l.svg" alt=""></span></a>
                </div>
              </div>
            </div> -->
          </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'FooterView',
}
</script>

<style>
.sec-footer .box-content small { font-size: 12px;}
</style>