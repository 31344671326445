<template>
   <div class="st position-relative  st-video st-video-layer-loop  " >
        
        <div class="container container-1600 position-relative">
            <div class="ele-hashtag-left" >SHOWREEL</div>
            <div class="video-container" :class="checkContain">
                <div class="thumbnail video-thumb video-loop" :class="{fadeOut: playing}">
                    <div class="play-button" @click="playVideo" >
                        <img src="../../assets/image/skin/icon-play.svg" alt="">
                        <b><span>Play Showreel</span></b>
                    </div>
                    <!-- <picture >
                        <source media="(max-width: 787px)" srcset="../../assets/image/home/video-thumb-m.jpg" sizes="">
                        <img class="fw" src="../../assets/image/home/video-thumb.jpg" alt="">
                    </picture> -->
                    <video autoplay loop muted>
                        <source src="../../assets/image/video/showreel_5s.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="video-show-real video-play" >
                    <video ref="myVideo"  @ended="playing = false" >
                        <source src="../../assets/image/video/showreel_full.mp4" type="video/mp4">
                    </video>
                </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoLoopLayer',
    data(){
        return{
            playing: false,
            thumbnail: '../assets/image/home/video-thumb.jpg',
            videoSrc: '../assets/image/video/istockphoto-475338912-640_adpp_is.mp4',
            checkContain: '',
            positionHas: 0
            
        }
    },
    mounted(){
        this.videoContainer();
        window.addEventListener('resize', ()=>{
            this.videoContainer();
            // this.positionHasTag();
        });

        // window.addEventListener('load', ()=>{
        //     this.positionHasTag();
        // });
        
    },
    methods: {
        // positionHasTag(){
        //     console.log((document.querySelector('.video-container').getBoundingClientRect().x));
        //      this.positionHas = (document.querySelector('.video-container').getBoundingClientRect().x / 2) - 20
        // },
        
        videoContainer(){
            if (window.innerWidth > 1599) {
               this.checkContain = 'container container-1600 my-3 p-0'; 
            }else{
                this.checkContain = ''; 
            }
        },
        playVideo() {
            this.playing = true
            setTimeout(() => {
                if (this.playing == true) {
                this.$refs.myVideo.play()
                }
            }, 100);
            
        }
        
    }
}
</script>

<style>
.video-container{width: 100%;display: block;position: relative;overflow: hidden;}
.video-container video{width: 100%;height: 100%;display: block;}
.thumbnail {
  width: 100%;
  height: auto;display: block;
}
.fadeOut {
  transition: opacity 0.5s;
  opacity: 0;
}
 .video-show-real{position: absolute;top: 0%; width: 100%; height: auto;z-index: -1;} 
 .play-button{    position: absolute;bottom: 20px;left: 20px;z-index: 2;display: flex;align-items: center;cursor: pointer;}
 .play-button span{color: #fff;margin-left:10px;}
 .play-button img{width: 30px;}
.image-onvideo-preview picture { position: absolute; top: 0; left: 0; float: left; mix-blend-mode: screen; }

 .ele-hashtag-left { display:block;font-size: 8em;font-weight: bold;position: absolute;z-index: 0;left: -192px;bottom: 26px;width: 192px;height: auto;transform: rotate(-90deg);text-transform: uppercase;-webkit-text-stroke: 0.5px #ffffff;color: transparent;}
 @media (max-width: 1799.98px){	
    .ele-hashtag-left {display: none;}
 }
 @media (min-width: 1599.98px){	
 }
 @media (max-width: 767.98px){	
    .ele-hashtag-left{left: -140px;display: block;}
 }
</style>