<template>
    <div  class="global-popup section-popup">
        <div  class="popup-bg" @click="handlePopContentClick()"></div>
        <div class="popup-container" data-state="1">
            <div  class="popup-close popout" @click="handlePopContentClick()" data-popup="popup-content">X</div>
            <div class="box-popup">
                <div class="popup-body scroll">
                    <div class="box-v">
                        <div class="ganeral-v">
                            <div class="v-container inactive-state click-to-play-v " ref="player">
                                <div class="v-put" data-video="RuORKyaDPCo" data-code="1" section_type="video">
                                    <div id="player_ganeral_1_video" class="player_ganeral " ref="iframe"></div>
                                </div>
                                <div class="v-space d-flex h-100">
                                    <img class="float-left w-100" style="object-fit: cover;" src="@/assets/image/home/bg-home-banner-d.jpg">
                                </div>
                                <div class="v-icon">play</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
/* eslint-disable */
import { TweenMax } from 'gsap';
export default {
    name: 'PopupVideo',
    props: ['popupCont'],
    data(){
        return{
            videoID:'',
            showPopupInner : false,
            popupCont_out : ''
        }
    },
    mounted() {
        if(this.popupCont.videoID_props != ''){
            TweenMax.to('.global-popup',.2, {autoAlpha:1}); 
            document.querySelector('.section-popup .v-put').setAttribute("data-video", this.popupCont.videoID_props);
            this.videoID = this.popupCont.videoID_props;
            console.log(this.videoID);
        }

        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        var dataState = document.querySelector(".click-to-play-v").closest(".popup-container");

        document.querySelector(".click-to-play-v").addEventListener("click", function (event) {
            var time_update_interval = 0;
            var dataState = event.target.closest(".popup-container");
            var dataVideo_ganeral = event.target.getAttribute("data-video");
            var section_type = event.target.getAttribute("section_type");
            var data_code = event.target.getAttribute("data-code");
            var str = "player_ganeral_" + data_code + "_" + section_type;

            // var dataVideo_ganeral = this.videoID;
            // var str = 'player_ganeral_1_video';
            
            var videoPlayer = new window.YT.Player(str, {
                width: "100%",
                height: "100%",
                videoId: dataVideo_ganeral,
                playerVars: {
                    autoplay: 1,
                    controls: 1,
                    showinfo: 0,
                    modestbranding: 0,
                    iv_load_policy: 3,
                },
                events: {
                    onReady: function (event) {
                        event.target.playVideo();
                        if (dataState.getAttribute("data-state") == 1) {
                            dataState.setAttribute("data-state", "0");
                        }
                    },
                    onStateChange: function (event) {
                        clearInterval(time_update_interval);
                        time_update_interval = setInterval(function () {
                            if ( event.data == window.YT.PlayerState.ENDED || dataState.getAttribute("data-state") == 1) {
                                if (event.data == window.YT.PlayerState.ENDED) {
                                    event.target.destroy();
                                }
                                if (dataState.getAttribute("data-state") == 1) {
                                    event.target.pauseVideo();
                                }
                                dataState.setAttribute("data-state", "0");
                                clearInterval(time_update_interval);
                            }
                        }, 100);
                    },
                },
            });
        });
        
        
    },
    methods:{
        handlePopContentClick(){
            this.$emit('popup-out', false)
            TweenMax.to('.global-popup',.2, {autoAlpha:0});
        }
    }
}
</script>
<style>
</style>