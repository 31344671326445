<template>
    
    <div class="st st-work-list pd ">
        <div class="container-fluid ">
            <div class="box-content d-block d-md-none text-start">
                <h2 class="text-light text-upper text-bold mb-3 mb-md-0"><span class="didot-roman weight-normal text-light ">RECENT</span>  WORKS</h2>
            </div>
            <div class="clearfix"></div>
            <div :class="classFw">
                <div :class="classRow">
                    <div :class="classCol">
                        <a href="javascript:void(0)" class="relex"  data-speed-scroll="0.2">
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                    <img  src="@/assets/image/home/works/Regal-FC.jpg" 
                                        class="attachment-medium size-medium wp-post-image" alt="" 
                                        decoding="async" >
                                </div>
                                <div class="card-item-content">
                                    <div class="item-content-h"><b>Regal FC Year 2</b></div>
                                    <div class="item-content-p">Campaign, TVC</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div :class="classCol">
                        <a href="javascript:void(0)" class="relex"  data-speed-scroll="0.14">
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                        <img  src="@/assets/image/home/works/True-5G.jpg" class="attachment-medium size-medium wp-post-image" alt="" decoding="async"  >
                                    </div>
                                    <div class="card-item-content">
                                        <div class="item-content-h"><b>True 5G</b></div>
                                        <div class="item-content-p">Web, app</div>
                                    </div>
                            </div>
                        </a>
                    </div>
                    <div :class="classCol">
                        <a href="javascript:void(0)" class="relex"  data-speed-scroll="0.2">
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                        <img  src="@/assets/image/home/works/Absolute-AWO.jpg" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" >
                                    </div>
                                    <div class="card-item-content">
                                    <div class="item-content-h"><b>Absolut </b></div>
                                    <div class="item-content-p">Social</div>
                                    </div>
                            </div>
                        </a>
                    </div>
                    <div :class="classCol" >
                        <a href="javascript:void(0)" class="relex relex-1" data-speed-scroll="0.17" >
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                        <img src="@/assets/image/home/works/Jameson-Connects.jpg" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" >
                                    </div>
                                    <div class="card-item-content">
                                    <div class="item-content-h"><b>Jameson Connects	 </b></div>
                                    <div class="item-content-p">Campaign, TVC</div>
                                    </div>
                            </div>
                        </a>
                    </div>
                    
                    <div :class="classCol">
                        <a href="javascript:void(0)" class="relex"  data-speed-scroll="0.1">
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                        <img src="@/assets/image/home/works/28-SS-Galaxy.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" >
                                    </div>
                                    <div class="card-item-content">
                                    <div class="item-content-h"><b>Samsung Galaxy S23</b></div>
                                    <div class="item-content-p">Social</div>
                                    </div>
                            </div>
                        </a>
                    </div>

                    <div :class="classCol">
                        <a href="javascript:void(0)" class="relex"  data-speed-scroll="0.17">
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                        <img src="@/assets/image/home/works/16-Mordee.png" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" >
                                    </div>
                                    <div class="card-item-content">
                                    <div class="item-content-h"><b>Mordee</b></div>
                                    <div class="item-content-p">Campaign, TVC</div>
                                    </div>
                            </div>
                        </a>
                    </div>
                    <div :class="classCol">
                        <a href="javascript:void(0)" class="relex"  data-speed-scroll="0.1">
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                        <img src="@/assets/image/home/works/Vivo.jpg" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" >
                                    </div>
                                    <div class="card-item-content">
                                    <div class="item-content-h"><b>Vivo</b></div>
                                    <div class="item-content-p">Campaign, TVC</div>
                                    </div>
                            </div>
                        </a>
                    </div>
                    <div :class="classCol">
                        <a href="javascript:void(0)" class="relex"  data-speed-scroll="0.17">
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                        <img src="@/assets/image/home/works/SCGC.jpg" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" >
                                    </div>
                                    <div class="card-item-content">
                                    <div class="item-content-h"><b>SCG Chemicals</b></div>
                                    <div class="item-content-p">Web, app</div>
                                    </div>
                            </div>
                        </a>
                    </div>
                    <div :class="classCol">
                        <a href="javascript:void(0)" class="relex"  data-speed-scroll="0.1">
                            <div  class="card card-work" >
                                <div class="card-image object-fit card-img">
                                        <img src="@/assets/image/home/works/ttb-Wow.jpg" class="attachment-medium size-medium wp-post-image" alt="" decoding="async" >
                                    </div>
                                    <div class="card-item-content">
                                    <div class="item-content-h"><b>ttb Wow</b></div>
                                    <div class="item-content-p">Web, app</div>
                                    </div>
                            </div>
                        </a>
                    </div>
                    


                    <!-- <div class="d-md-block d-none" :class="classColBtn">
                        <div class="relex pb-5"  data-speed-scroll="0.03">
                            <div class="fw btn-relex btn-relex-d">
                                <a   class="btn btn-primary text-upper"  href="" >see all works</a> 
                            </div> 
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="d-md-none d-block" :class="classColBtn">
                <div class="relex pb-5"  data-speed-scroll="0.03">
                    <div class="fw btn-relex pd text-start">
                        <a class="btn btn-primary text-upper"  href="" >see all works</a> 
                    </div> 
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import Scrollbar from "smooth-scrollbar";
import 'swiper/swiper-bundle.min.css'
export default {
    name: 'WorkList',
    data(){
        return{
            classFw: '',
            classRow: '',
            classCol:'',
            classColBtn: '',
            
        }
    },
    mounted() {
        if(window.innerWidth >= 768){
            this.relexScroll();
        }
        this.updateClass();
        window.addEventListener('resize', ()=>{
            
            if(window.innerWidth >= 768){
                this.relexScroll();
            }
            this.updateClass();
        });
        window.addEventListener('load', () =>{
        });

        // element.style.transform = `translate3d(0, ${(status.offset.y - (eleOffset.top +parseFloat(paddingTopValue))) * speed}px, 0)`
    },
    methods:{
        /* eslint-disable */
        sendVideoID(videoID) {
            this.$emit('videoIDSent', videoID);
        },
        
        updateClass() {
            if (window.innerWidth >= 768) {
                this.classFw = 'fw';
                this.classRow = 'row';
                this.classCol = 'col-12 col-md-4 col-xl-4';
                this.classColBtn = 'col-12';
            }
            if (window.innerWidth < 768) {
                this.classFw = 'swiper-container';
                this.classRow = 'swiper-wrapper';
                this.classCol = 'swiper-slide';
                this.classColBtn = 'fw ';
            }
        },
        
        relexScroll(){
            var elementOfset = 0;
            this.scrollbar = Scrollbar.init(document.querySelector('.my-scrollbar'));

            const relexIxdex = document.querySelectorAll(".relex");
            const scrollableInner = document.querySelector('.scrollable-inner');
            const paddingTop = getComputedStyle(scrollableInner).paddingTop;
            const paddingTopValue = paddingTop.replace("px", "");

           
                relexIxdex.forEach((element,index) => {
                    const eleOffset = element.getBoundingClientRect();
                    // const eleOffsetDefluat =  eleOffset.top;
                    
                    this.scrollbar.addListener((status) => {
                        var speed = element.getAttribute("data-speed-scroll");
                        if (window.innerHeight >= element.getBoundingClientRect().top ) {
                            element.style.transform = `translate3d(0, -${ (window.innerHeight - element.getBoundingClientRect().top ) * speed}px, 0)`;
                            // element.style.transform = `translate3d(0, ${ (window.innerHeight - element.getBoundingClientRect().top )}px, 0)`
                            // element[index].style.transform = `translate3d(0, ${ (window.innerHeight - element.getBoundingClientRect().top )}px, 0)`;
                            // console.log(status.offset.y , document.querySelectorAll(".relex-1")[0].getBoundingClientRect().top , index);
                        }
                    
                            
                            // if (status.offset.y >= eleOffset.top) {
                            //     // element.style.transform = `translate3d(0, ${elementOfset * speed}px, 0)`
                            //     // this.translateXText =  firstCont.offsetHeight -  (scrollbar.offset.y+ window.innerHeight);
                            // }
                            

                          
                            
                            
                    });
            });
        }
    }
}
</script>

<style scoped>
/* .st-work-list{overflow: hidden;} */
.st-work-list .container-fluid{padding:0 80px;}
.card.card-work{margin-bottom: 40px;background-color: transparent;border: 0; width: 80%; margin: 0 auto;}
/* .card-work {padding: 0 40px;} */
.card-work .card-img{overflow: hidden;    border-radius: 0;}
/* .card-work .card-img img{width: 100%;height: 100%;object-fit: cover;} */
 .btn-relex-d{padding-top: 50px;}
.card-work .item .item-img{width: 100%;float: left;border-radius: 200px;overflow: hidden;margin-bottom: 10px;}
.card-work .item .item-img img{width: 100%;}
.card-item-content {width: 100%;text-align: left;}
.card-work .item .item-content{width: 100%;float: left;padding: 0 30px;}
.item-content-h{font-size:2.3em;color: #23d5ab;text-transform: uppercase;line-height: 1.2;margin-bottom: 10px;}
.item-content-p{font-size:1.3em;color: #fff;text-transform: uppercase;}
.relex{  width: 100%;display: flow-root;-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0); transform: translateZ(0); -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; }
/* .st-work-list .card-work .object-fit:before {
    padding-bottom: 177.777778%;
} */

.st-client .client-group .item { opacity: 0.5;}
.object-fit:before {
    content: "";
    display: block;
}
.object-fit {
    position: relative;
    display: block;
    overflow: hidden;
}
.st-work-list .card-work .object-fit > *:not(script) {
    transform: scale(1);
    transition: 1.4s;
}

.object-fit > img, .object-fit > video, .object-fit > iframe {
    display: block;
    margin: 0 auto;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    /* position: absolute; */
    top: 0;
    left: 0;
    float: left;width: 100%;
}
.card-video video {
    display: block;
    width: 100%;
}
.card-image > img, .card-image > video, .card-image > iframe {
    display: block;
    width: 100%;
    height: auto;
}
.card-image {
    display: block;
    overflow: hidden;
}
.card-work img:not(.img-flexible) {
    width: auto;
}
.st-work-list .card-work > *:not(:last-child){margin-bottom: 30px;}
@media (max-width: 1199.98px) {
    .st-work-list .container-fluid { padding: 0 40px;}
    .card.card-work { width: 90%;}
}


@media (max-width: 991.98px) {	
    .st-work-list .container-fluid { padding: 0 35px;}
    .card.card-work { width: 100%;}
    .st-work-list .card-work > *:not(:last-child) { margin-bottom: 15px;}
    .item-content-h { font-size: 1.5em;}
    .item-content-p { font-size: 1em;}
}
@media (max-width: 767.98px) {	
    .st.scrollable-inner{padding-top: 300px;}
    .st-work-list{overflow: hidden;}

    .st-work-list .container-fluid { padding:0 20px;}
  
}
</style>