/* eslint-disable */
export function setFunction() {
    genHeight();
    svg();


    document.addEventListener("scroll", onScroll);
      function onScroll(event) {
        var scrollPos = window.pageYOffset;
        document.querySelectorAll('.menu-center a').forEach(function(currLink) {
           
          var refElement = document.querySelector(currLink.getAttribute("href"));
          if (refElement.offsetTop <= scrollPos && (refElement.offsetTop + refElement.offsetHeight) > scrollPos) {
            document.querySelectorAll('ul.menu-center  li a').forEach(function(anchor) {
              
              anchor.classList.remove("active");
            });
            currLink.classList.add("active");
          } else {

            currLink.classList.remove("active");
          }
        });
      }
      
 
    //loadpage();
}
window.addEventListener('load', () => {
    genHeight('2240');
    svg();
    // galaxy();
    // TweenMax.to('.loadpage', 1, { autoAlpha: 0 });
});


window.addEventListener('resize', () => {
    var winW = window.innerWidth;
    if (winW >= 1600 && winW <= 1920) {
        genHeight('1920');
    } else if (winW >= 1440 && winW < 1600) {
        genHeight('1600');
    } else if (winW >= 1200 && winW < 1440) {
        genHeight('1440');
    } else if (winW >= 992 && winW < 1200) {
        genHeight(1200);
    } else if (winW >= 768 && winW < 992) {
        genHeight('992');
    } else if (winW < 768) {
        genHeight('');
    } else if (winW > 1920) {
        genHeight('2240');
    }

});



svg()
function svg() {
    document.querySelectorAll("img.svg").forEach(function (img) {
        var imgID = img.getAttribute("id");
        var imgClass = img.getAttribute("class");
        var imgURL = img.getAttribute("src");
        var xhr = new XMLHttpRequest();
        xhr.open("GET", imgURL, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                var data = xhr.responseXML;
                var svg = data.querySelector("svg");
                if (typeof imgID !== "undefined") {
                    svg.setAttribute("id", imgID);
                }
                if (typeof imgClass !== "undefined") {
                    svg.setAttribute("class", imgClass + " replaced-svg");
                }
                svg.removeAttribute("xmlns:a");
                if (
                    !svg.getAttribute("viewBox") &&
                    svg.getAttribute("height") &&
                    svg.getAttribute("width")
                ) {
                    svg.setAttribute(
                        "viewBox",
                        "0 0 " +
                        svg.getAttribute("height") +
                        " " +
                        svg.getAttribute("width")
                    );
                }
                img.parentNode.replaceChild(svg, img);
            }
        };
        xhr.setRequestHeader(
            "Content-Type",
            "application/x-www-form-urlencoded; charset=UTF-8"
        );
        xhr.send();
    });
}

function genHeight(widthCheckX) {
    const winW = window.innerWidth;
    const elements = document.querySelectorAll('.gen-height');
    elements.forEach((element) => {
        const selfWidth = element.offsetWidth;
        const dataColumn = element.getAttribute('data-column');
        let source = winW > 767 ?
            element.getAttribute('data-bg-d') :
            element.getAttribute('data-bg-m');
        const widthCheck = widthCheckX / dataColumn;
        const image = new Image();

        image.onload = function () {
            let height;
            if (dataColumn > 1) {
                height = (selfWidth * this.height) / this.width;
                console.log((selfWidth * this.height) / this.width);
                // console.log('height',height);
            } else {
                height = (widthCheck === "" ? window.innerWidth / dataColumn : widthCheck) * this.height / this.width;
            }
            height += 'px';


            element.getElementsByClassName('endH')[0].style.minHeight = height;
            // if (element.closest('.section').classList.contains('st-banner')) {
            //     element.style.height = height;
            // }else{

            // }

        };
        image.src = source;
    });
}


//////////////////////////////
/////// BANNER SLIDE /////////
/////////////////////////////
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
var slideOnStop = 1;
var st_banner;
window.addEventListener('load', () => {


    var playerBanner;

    let banners = document.querySelectorAll('.st-work-list');
    banners.forEach((banner) => {
        st_banner = new Swiper(banner.querySelector('.swiper-container'), {
            // configure Swiper to use modules
            modules: [Navigation, Pagination, Autoplay],
            slidesPerView: 1.3,
            loop: false,
            spaceBetween: 40,
            navigation: {
                nextEl: banner.querySelector('.swiper-button-next'),
                prevEl: banner.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                991: {
                    slidesPerView: 1,
                    simulateTouch: true,
                },
                768: {
                    slidesPerView: 1,
                    simulateTouch: true,
                },
            }
        });
       
    });


});



// function galaxy() {
//     "use strict";

//     var canvas = document.getElementById('canvas_galaxy'),
//         ctx = canvas.getContext('2d'),
//         w = canvas.width = window.innerWidth,
//         h = canvas.height = window.innerHeight,

//         hue = 217,
//         stars = [],
//         count = 0,
//         maxStars = 1400;

//     // Thanks @jackrugile for the performance tip! https://codepen.io/jackrugile/pen/BjBGoM
//     // Cache gradient
//     var canvas2 = document.createElement('canvas'),
//         ctx2 = canvas2.getContext('2d');
//     canvas2.width = 100;
//     canvas2.height = 100;
//     var half = canvas2.width / 2,
//         gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);
//     gradient2.addColorStop(0.025, '#fff');
//     gradient2.addColorStop(0.1, 'hsl(' + hue + ', 61%, 33%)');
//     gradient2.addColorStop(0.25, 'hsl(' + hue + ', 64%, 6%)');
//     gradient2.addColorStop(1, 'transparent');

//     ctx2.fillStyle = gradient2;
//     ctx2.beginPath();
//     ctx2.arc(half, half, half, 0, Math.PI * 2);
//     ctx2.fill();

//     // End cache

//     function random(min, max) {
//         if (arguments.length < 2) {
//             max = min;
//             min = 0;
//         }

//         if (min > max) {
//             var hold = max;
//             max = min;
//             min = hold;
//         }

//         return Math.floor(Math.random() * (max - min + 1)) + min;
//     }

//     function maxOrbit(x, y) {
//         var max = Math.max(x, y),
//             diameter = Math.round(Math.sqrt(max * max + max * max));
//         return diameter / 2;
//     }

//     var Star = function () {

//         this.orbitRadius = random(maxOrbit(w, h));
//         this.radius = random(60, this.orbitRadius) / 18;
//         this.orbitX = w / 2;
//         this.orbitY = h / 2;
//         this.timePassed = random(0, maxStars);
//         this.speed = random(this.orbitRadius) / 1500000;
//         this.alpha = random(2, 10) / 10;

//         count++;
//         stars[count] = this;
//     }

//     Star.prototype.draw = function () {
//         var x = Math.sin(this.timePassed) * this.orbitRadius + this.orbitX,
//             y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY,
//             twinkle = random(10);

//         if (twinkle === 1 && this.alpha > 0) {
//             this.alpha -= 0.05;
//         } else if (twinkle === 2 && this.alpha < 1) {
//             this.alpha += 0.05;
//         }

//         ctx.globalAlpha = this.alpha;
//         ctx.drawImage(canvas2, x - this.radius / 2, y - this.radius / 2, this.radius, this.radius);
//         this.timePassed += this.speed;
//     }

//     for (var i = 0; i < maxStars; i++) {
//         new Star();
//     }

//     function animation() {
//         ctx.globalCompositeOperation = 'source-over';
//         ctx.globalAlpha = 0.8;
//         ctx.fillStyle = 'hsla(' + hue + ', 0%, 6%, 1)';
//         ctx.fillRect(0, 0, w, h)

//         ctx.globalCompositeOperation = 'lighter';
//         for (var i = 1, l = stars.length; i < l; i++) {
//             stars[i].draw();
//         };

//         window.requestAnimationFrame(animation);
//     }

//     animation();
// }


