<template>
    <div class="st pd pb-0 h-auto align-items-end sec-letus bg-fullwidth gen-bg sec-letushelp"  genbg="genBg"
      :data-bg-d="`${require('@/assets/image/home/bg-letus-d.jpg')}`" 
      :data-bg-m="`${require('@/assets/image/home/bg-home-banner-m.jpg')}`"  >
        <div class="container-fluid">
            <div class="fw  position-relative">
                <img class="ele-global" src="../../assets/image/home/banner-global.png" alt="">
                <img class="ele-patform d-none d-md-block" src="../../assets/image/home/banner-patform.png" alt="">
                <img class="ele-patform d-block d-md-none" src="../../assets/image/home/banner-patform-mobile.png" alt="">
                <div class="contact-footer-cont">
                    <div class="box-content text-start">
                        <h2 class="text-upper text-bold text-light mb-3">Let's help your<br><span class=" text-bold text-ctr-primary">business <span style="display:inline-block;">grow</span></span></h2>
                        <ul class="mb-2 ul-list-none">
                            <li><a class="btn-icon text-light" href="tel:+66818191122"><span><img style="height: 18px;width: 18px;" src="../../assets/image/skin/icon-phone.svg" alt=""><span class="text-contract ms-2">+6681.819.1122</span></span></a></li>
                            <li><a  target="_blank"  class="btn-icon text-light"  href="mailto:Marketing@digitalnex.com"><span><img src="../../assets/image/skin/icon-mail.svg" alt=""><span class="text-contract ms-2">Marketing@digitalnex.com</span></span></a></li>
                            <li><a  target="_blank"  style="display: flex; justify-items: center;" class="btn-icon text-light"   href="https://www.facebook.com/people/Digitalnex/100039395115400/"><span ><img src="../../assets/image/skin/icon-facebook.svg" alt=""><span class="text-contract ms-2">Facebook</span></span></a></li>
                            <li><a  target="_blank"  style="display: flex; justify-items: center;" class="btn-icon text-light icon-in"  href="https://www.linkedin.com/company/digitalnex/about/"><span ><img src="../../assets/image/skin/icon-linkin.svg" alt=""><span class="text-contract ms-2">LinkedIn</span></span></a></li>
                        </ul>
                    <!-- <div class="fw">
                        <div class="so-txt text-light">Follow us</div>
                        <div class="social ms-0 text-start  mt-2">
                            <a class="ms-0" href=""><span><img src="../../assets/image/skin/icon-facebook.svg" alt=""></span></a>
                            <a href=""><span><img src="../../assets/image/skin/icon-linkin.svg" alt=""></span></a>
                            <a href=""><span><img src="../../assets/image/skin/icon-twitter.svg" alt=""></span></a>
                            <a href=""><span><img src="../../assets/image/skin/icon-youtube.svg" alt=""></span></a>
                            <a href=""><span><img src="../../assets/image/skin/icon-l.svg" alt=""></span></a>
                        </div>
                    </div> -->
                    </div>
                </div>
                <div class="group-human">
                    <div class="human"><img src="../../assets/image/home/human.png" alt=""></div>
                    <div class="text-circle">
                        <span style="--i:1;">l</span>
                        <span style="--i:2;">e</span>
                        <span style="--i:3;">t</span>
                        <span style="--i:4;">'</span>
                        <span style="--i:5;">s</span>
                        <span style="--i:6;"> </span>
                        <!-- <span style="--i:5;">u</span>
                        <span style="--i:6;">s </span> -->
                        <!-- <span style="--i:7;"> </span> -->
                        <span style="--i:7;">h</span>
                        <span style="--i:8;">e</span>
                        <span style="--i:9;">l</span>
                        <span style="--i:10;">p</span>
                        <span style="--i:11;"> </span>
                        <span style="--i:12;">y</span>
                        <span style="--i:13;">o</span>
                        <span style="--i:14;">u</span>
                        <span style="--i:15;">r</span>
                        <span style="--i:16;"> </span>
                        <span style="--i:17;">b</span>
                        <span style="--i:18;">u</span>
                        <span style="--i:19;">s</span>
                        <span style="--i:20;">i</span>
                        <span style="--i:21;">n</span>
                        <span style="--i:22;">e</span>
                        <span style="--i:23;">s</span>
                        <span style="--i:24;">s</span>
                        <span style="--i:25;"></span>
                        <span style="--i:26;">g</span>
                        <span style="--i:27;">r</span>
                        <span style="--i:28;">o</span>
                        <span style="--i:29;">w</span>
                        <span style="--i:30;"> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import browserDetect from 'vue-browser-detect-plugin';

export default {
    name: 'LetUsHelp',

    mixins: [browserDetect],
    data(){
        return{
            checkBrowser:true,
            getImage:'',
        }
    },
    mounted(){
        var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        if (isSafari) {
            this.checkBrowser = false;
            console.log();
        }else{
            this.checkBrowser = true;
        }
        
            this.setBackgroundImage();
        window.addEventListener('resize', ()=>{
            this.setBackgroundImage();
        });

    },
    methods:{
        setBackgroundImage() {
            let genBgEle = document.querySelectorAll('.gen-bg');
            genBgEle.forEach((element) => {
                if (window.matchMedia("(min-width: 768.98px)").matches) {
                    this.getImage = element.getAttribute('data-bg-d');
                }else {
                    this.getImage = element.getAttribute('data-bg-m');
                }
                element.style.backgroundImage = `url(${this.getImage})`;
            });
        },
    }
}
</script>

<style>
/* .ps-b0-l0{position: absolute;bottom: 0;left: 0;} */
    .sec-letushelp:before { content: "";position: absolute;top: 0;left: 0;width: 100%;height: 30%;background: rgb(0,0,0);background: linear-gradient(0deg, rgba(0,0,0,0) 13%, rgba(0,0,0,1) 100%);}
    .ele-patform{position: absolute;bottom: 0;left: -15px;z-index: 1; width: 40%;}
    .group-human{transform-style: preserve-3d;position: relative;max-width: 19%;float: left;    position: absolute; bottom: 2%;left: 20%;pointer-events: none; z-index: 1;}
    .human{width: auto;float: left;}
    .text-circle{background-color: #fff; transform-style: preserve-3d;animation:animateText 10s linear infinite;    position: absolute;top: 30%;left: 50%;}
    .text-circle span{ position: absolute;top: 0;left: 0;font-weight: bold; background: transparent;color: #02FFB3;font-size: 2.5vw;transform-origin: center;transform-style: preserve-3d;transform: rotateY(calc(var(--i) * calc(360deg / 30))) translateZ(13vw);text-transform: uppercase;}
    .ele-global{width: 100%;float: left;}
    .text-contract:hover{color: #02FFB3;}
    @keyframes animateText{
        0%{transform: perspective(1000px) rotateY(360deg) rotateX(-10deg)  }
        100%{transform: perspective(1000px) rotateY(0deg) rotateX(-10deg) }
    } 
 
   .icon-in > span > span{margin-top: 6px;}
   .contact-footer-cont {width: 50%;position: absolute;left: 50%;top: 50%;transform: translateY(-50%);}
   .contact-footer-cont .box-content h4{    font-size: 2.45em;margin-bottom: 20px;}
   .contact-footer-cont .box-content h2{    font-size: 3.5em;line-height: 1.2;}
   .contact-footer-cont ul .btn-icon span img { width: 16px; height: 16px; }
   .contact-footer-cont ul li { padding-bottom: 10px;}
   .earth-banner {position: absolute;right: 0;width: auto;top: 50%;}
   .hero-earth{position: relative;width: 100%;float: left;}
   .hero-earth img{width: 100%;float: left;}
   .group-earth{transform-style: preserve-3d;width: auto;float: left; }
   .circle-earth{background-color: #fff; transform-style: preserve-3d;animation:animateEarth 40s linear infinite;    position: absolute;top: 50%;left: 50%;}
   .circle-earth span {position: absolute;top: 0;left: 0;width: calc(100vmax / 85);background: transparent;background-color: #02BB9B;height: 0.1px;transform-origin: center;transform-style: preserve-3d;transform: rotateY(calc(var(--i) * calc(360deg / 150))) translateZ(620px);text-transform: uppercase;}   
   .circle-earth-2{background-color: #fff; transform-style: preserve-3d;animation:animateEarth 28s linear infinite;    position: absolute;top: 50%;left: 50%;}
   .circle-earth-2 span {border-radius: 100px;position: absolute;top: 0;left: 0;width: calc(100vmax / 110);background: #02BB9B;height: 1px;transform-origin: center;transform-style: preserve-3d;transform: rotateY(calc(var(--i) * calc(360deg / 150))) translateZ(470px);}
   @keyframes animateEarth{
     0%{transform: perspective(1000px) rotateY(360deg) rotateX(-5deg)  }
     100%{transform: perspective(1000px) rotateY(0deg) rotateX(-5deg) }
   } 
   @keyframes rotate {
     0% {
       background-position: 0 0;
     }
     100% {
       background-position: 980px, 0;
     }
   }

@media (max-width: 1199.98px) {	
    .ele-patform{width: 50%;}
}

@media (max-width: 991.98px) {	
    .group-human{}
    .ele-global {float: left;position: absolute;bottom: 0;left: 0;max-width: unset;}
    .service-cont blockquote { padding-top: 40px; padding-left: 0; border-left: 0;margin: 0;}
    .ps-b0-l0{position: relative;}
    .contact-footer-cont{position: relative;top: 0;}
    .contact-footer-cont .box-content h2 { font-size: 2.5em;}

    .st.sec-letushelp .fw {padding-top: 100px; padding-bottom: 60px;}
}

@media (max-width: 767.98px) {	

    .sec-letus{overflow: hidden;} 
   
    .sec-full-service-digital{margin-top: 0;}
 
    .ele-global {width: 240%;}
    .ele-patform { width:100vw; max-width: none; margin-left: -20px;left:0px; }
    
    .good-work{display: none;}
    .scroll-content{overflow: hidden;}
    .st.sec-letushelp .fw { padding-bottom: 0;}
    .contact-footer-cont { left: 0; width: 100%;}
    .group-human { position: relative; width: 50%; max-width: none;}
    .text-circle span{    transform:rotateY(calc(var(--i) * calc(360deg / 33))) translateZ(36vw);font-size: 7.5vw;}

    .contact-footer-cont .box-content h2 { font-size: 2.5em;}

}

@media (max-width: 767.98px) {	
    .st.sec-letushelp .fw{ padding-top: 0;}
}
</style>