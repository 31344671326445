
import { createApp } from 'vue';
// import { TweenMax } from 'gsap';
import { TweenMax } from 'gsap/all';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'animate.css';
import './assets/css/main.css';

import global from './global';
// import ui from './ui';
const app = createApp(App);
app.use(store);
app.use(router);
app.use(global);
// app.use(ui);
app.config.globalProperties.$TweenMax = TweenMax;
app.mount('#app');


// const textScrollLoop = document.querySelector('.text-scroll-loop');
// const textScrollItems = textScrollLoop.querySelectorAll('span');
// const textScrollHeight = textScrollItems[0].clientHeight; // get the height of each scroll item

// let index = 0;

// function animateTextScroll() {
//   textScrollLoop.style.transform = `translateY(-${index * textScrollHeight}px)`; // adjust translateY based on index
//   index = (index + 1) % textScrollItems.length; // increment index and loop back to 0 once it reaches the end
// }

// setInterval(animateTextScroll, 3000); // call the function every 3 seconds, adjust the interval as needed
